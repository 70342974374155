import React from "react";

function ContentHeader({ children, style, className }) {
  return (
    <h1
      className={`py-[30px] m-0 font-bold ${
        className ? className : "text-[#cb1c1e]"
      }`}
      style={style}
      children={children}
    >
      {children}
    </h1>
  );
}

export default ContentHeader;
