import React from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";

// Router/Links
import Home from "./Routes/Home";
import ProfessionalTeam from "./Routes/ProfessionalTeam";
import Management from "./Routes/Management";
import ServicesProducts from "./Routes/Services&Products";
import TargetMarket from "./Routes/TargetMarket";
import ContactUs from "./Routes/ContactUs";
import Amenities from "./Routes/Amenities";
import SteveBikoPrecinct from "./Routes/SteveBikoPrecinct";
import Parsonsvlei from "./Routes/Parsonsvlei";
import AboutSteveBiko from "./Routes/AboutSteveBiko";
import Rooms from "./Routes/Rooms";
import PostTemplate from "./Routes/Rooms/PostTemplate";
import ScrollToTop from "./modules/ScrollToTop";
import PressRelease from "./Routes/QuickLinks/PressRelease";
import NavPagesTemplate from "./Routes/Rooms/NavPagesTemplate";

function AnimeCSS() {
  return "animate__animated animate__fadeInUp";
}

function AnimatedComponents(props) {
  let location = useLocation();
  const { deviceWidth } = props;

  return (
    <TransitionGroup style={{ overflow: "hidden" }}>
      <CSSTransition key={location.key} classNames="fade" timeout={1000}>
        <Switch location={location}>
          {/* Quick Links */}
          <Route path="/press-release">
            <ScrollToTop
              seticonChange={props.seticonChange}
              setisNavShowing={props.setisNavShowing}
            />
            <PressRelease AnimeCSS={AnimeCSS} />
          </Route>
          {/* End Quick Links */}
          <Route path="/Rooms/:slug" exact>
            <ScrollToTop
              seticonChange={props.seticonChange}
              setisNavShowing={props.setisNavShowing}
            />
            <PostTemplate
              AnimeCSS={AnimeCSS}
              formModalHandleClick={props.formModalHandleClick}
              formModalRemoveHandleClick={props.formModalRemoveHandleClick}
              isFormModalon={props.isFormModalon}
            />
          </Route>
          <Route path="/Rooms">
            <ScrollToTop
              seticonChange={props.seticonChange}
              setisNavShowing={props.setisNavShowing}
            />
            <Rooms
              AnimeCSS={AnimeCSS}
              formModalHandleClick={props.formModalHandleClick}
              formModalRemoveHandleClick={props.formModalRemoveHandleClick}
              isFormModalon={props.isFormModalon}
              openVideo={props.openVideo}
            />
          </Route>
          <Route path="/AboutSteveBiko">
            <ScrollToTop
              seticonChange={props.seticonChange}
              setisNavShowing={props.setisNavShowing}
            />
            <AboutSteveBiko AnimeCSS={AnimeCSS} />
          </Route>
          <Route path="/home/ContactUs">
            <ScrollToTop
              seticonChange={props.seticonChange}
              setisNavShowing={props.setisNavShowing}
            />
            <ContactUs
              AnimeCSS={AnimeCSS}
              OpenModal={props.OpenModal}
              isModalon={props.isModalon}
              CloseModal={props.CloseModal}
              formModalHandleClick={props.formModalHandleClick}
              formModalRemoveHandleClick={props.formModalRemoveHandleClick}
              isFormModalon={props.isFormModalon}
              openVideo={props.openVideo}
            />
          </Route>
          <Route path="/home/TargetMarket">
            <ScrollToTop
              seticonChange={props.seticonChange}
              setisNavShowing={props.setisNavShowing}
            />
            <TargetMarket
              AnimeCSS={AnimeCSS}
              formModalHandleClick={props.formModalHandleClick}
              formModalRemoveHandleClick={props.formModalRemoveHandleClick}
              isFormModalon={props.isFormModalon}
            />
          </Route>
          <Route path="/home/ServicesProducts">
            <ScrollToTop
              seticonChange={props.seticonChange}
              setisNavShowing={props.setisNavShowing}
            />
            <ServicesProducts
              AnimeCSS={AnimeCSS}
              formModalHandleClick={props.formModalHandleClick}
              formModalRemoveHandleClick={props.formModalRemoveHandleClick}
              isFormModalon={props.isFormModalon}
            />
          </Route>
          <Route path="/home/amenities">
            <ScrollToTop
              seticonChange={props.seticonChange}
              setisNavShowing={props.setisNavShowing}
            />
            <Amenities
              AnimeCSS={AnimeCSS}
              formModalHandleClick={props.formModalHandleClick}
              formModalRemoveHandleClick={props.formModalRemoveHandleClick}
              isFormModalon={props.isFormModalon}
              deviceWidth={deviceWidth}
            />
          </Route>
          <Route path="/home/Parsonsvlei">
            <ScrollToTop
              seticonChange={props.seticonChange}
              setisNavShowing={props.setisNavShowing}
            />
            <Parsonsvlei AnimeCSS={AnimeCSS} />
          </Route>
          <Route path="/home/SteveBikoPrecinct">
            <ScrollToTop
              seticonChange={props.seticonChange}
              setisNavShowing={props.setisNavShowing}
            />
            <SteveBikoPrecinct
              AnimeCSS={AnimeCSS}
              formModalHandleClick={props.formModalHandleClick}
              formModalRemoveHandleClick={props.formModalRemoveHandleClick}
              isFormModalon={props.isFormModalon}
              openVideo={props.openVideo}
            />
          </Route>
          <Route path="/our-team/managment">
            <ScrollToTop
              seticonChange={props.seticonChange}
              setisNavShowing={props.setisNavShowing}
            />
            <Management AnimeCSS={AnimeCSS} />
          </Route>
          <Route path="/our-team/ProfessionalTeamFunders">
            <ScrollToTop
              seticonChange={props.seticonChange}
              setisNavShowing={props.setisNavShowing}
            />
            <ProfessionalTeam AnimeCSS={AnimeCSS} />
          </Route>
          <Route path="/about-us/:slug" exact>
            <ScrollToTop
              seticonChange={props.seticonChange}
              setisNavShowing={props.setisNavShowing}
            />
            <NavPagesTemplate AnimeCSS={AnimeCSS} />
          </Route>
          <Route path="/">
            <ScrollToTop
              seticonChange={props.seticonChange}
              setisNavShowing={props.setisNavShowing}
            />
            <Home
              AnimeCSS={AnimeCSS}
              OpenModal={props.OpenModal}
              isModalon={props.isModalon}
              CloseModal={props.CloseModal}
              formModalHandleClick={props.formModalHandleClick}
              formModalRemoveHandleClick={props.formModalRemoveHandleClick}
              isFormModalon={props.isFormModalon}
            />
          </Route>
        </Switch>
      </CSSTransition>
    </TransitionGroup>
  );
}

export default AnimatedComponents;
