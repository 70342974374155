import Axios from "axios";
import { AnimatePresence, motion } from "framer-motion";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import CreateApiUrl from "../../Context/ApiUrlCTX";
import SignupModal from "../../modules/QuickLinksData/SignupModal";
import VideoModal from "../../modules/QuickLinksData/VideoModal";
import NavList from "./NavList";
import Search from "./Search";

function AppNav(props) {
  const {
    formModalHandleClick,
    formModalRemoveHandleClick,
    isFormModalon,
    setisNavShowing,
    seticonChange,
    isVideoPlayerOn,
    openVideo,
  } = props;

  const { APIURL } = useContext(CreateApiUrl);
  const ImagesApi = `${APIURL}/wp-json/myroutes/Main-Menu`;

  const [images, setImages] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isErr, setIsErr] = useState(false);
  const formWidthRef = useRef();
  const formWidth = formWidthRef?.current?.clientWidth;

  useEffect(() => {
    Axios.get(ImagesApi)
      .then((res) => {
        setImages(res.data);
        setIsLoaded(true);
      })
      .catch((err) => {
        setIsLoaded(true);
        setIsErr(true);
      });
  }, [ImagesApi]);

  return (
    <>
      <header
        ref={formWidthRef}
        className="block w-full h-16 lg:h-auto lg:w-auto"
      >
        <nav className="fixed left-0 w-[85%] lg:w-[30%] h-16 bg-white drop-shadow-xl z-50">
          <Link
            className="absolute w-[220px] h-16 left-2 lg:left-[70px]"
            to="/"
          >
            <img
              className="block w-[220px] h-auto m-0 absolute top-[6px] box-border"
              src={`${APIURL}/wp-content/uploads/2022/06/Qhama-logo.png`}
              alt="Qhama"
            />
          </Link>
          <span
            onClick={
              props.isNavShowing ? props.RemoveHandleClick : props.HandleClick
            }
            className={`${
              props.iconChange ? "icon-menu4 span-active" : "icon-menu3"
            } icon block text-xl float-right m-0 top-5 right-[10%] p-0 absolute cursor-pointer z-50`}
          ></span>
        </nav>
        <AnimatePresence initial={{ y: "-100%" }}>
          {props.isNavShowing && (
            <motion.main
              initial={{ y: "-100%", opacity: 0.5 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: "-100%", opacity: 0.5 }}
              dragElastic={0}
              className={` drop-shadow-md fixed left-0 w-[85%] lg:w-[30%] h-screen bg-slate-50 z-40`}
            >
              <div className="absolute w-full h-full grid items-center content-center">
                <NavList
                  formModalHandleClick={formModalHandleClick}
                  formModalRemoveHandleClick={formModalRemoveHandleClick}
                  isFormModalon={isFormModalon}
                  setisNavShowing={setisNavShowing}
                  seticonChange={seticonChange}
                  images={images}
                  isLoaded={isLoaded}
                  isErr={isErr}
                />

                <div className="w-full absolute bottom-0 bg-[#ed1c24] block opacity-100">
                  <p className="text-center text-white text-base font-sans py-4">
                    Developed by{" "}
                    <Link
                      to="http://www.selectfew.co.za"
                      className="text-white font-bold"
                    >
                      Select Few (Pty) Ltd.
                    </Link>
                  </p>
                </div>
              </div>
            </motion.main>
          )}
        </AnimatePresence>

        {!props.isModalon ? (
          <Search
            HandleClickSearchon={props.HandleClickSearchon}
            RemoveHandleClickSearchon={props.RemoveHandleClickSearchon}
            iconChange={props.iconChange}
            isSearchon={props.isSearchon}
            setisSearchon={props.setisSearchon}
            formWidth={formWidth}
          />
        ) : null}
      </header>
      <AnimatePresence initial={{ opacity: 0 }}>
        {props.isNavShowing && (
          <motion.div
            onClick={() => {
              props.RemoveHandleClick();
            }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className={` fixed h-full w-full bg-[#ed1c23] translate-x-0 bg-opacity-40 backdrop-blur-sm top-0 bottom-0 right-0 cursor-pointer z-30`}
          />
        )}
      </AnimatePresence>

      {isFormModalon && (
        <SignupModal
          formModalRemoveHandleClick={formModalRemoveHandleClick}
          isFormModalon={isFormModalon}
          setisNavShowing={setisNavShowing}
          seticonChange={seticonChange}
        />
      )}

      {isVideoPlayerOn && <VideoModal openVideo={openVideo} />}
    </>
  );
}

export default AppNav;
