import React from "react";

function BannerInfoAlt({ children }) {
  const content = (
    <figure className="border-l-[5px] border-[#ed1c24] absolute top-0 right-0 m-0 w-full lg:w-[450px] h-full bg-black bg-opacity-40 z-10 box-border">
      <div className="banner-price signup-banner">
        <div>
          <div className="textwidget">{children}</div>
        </div>
      </div>
    </figure>
  );

  if (children !== undefined) {
    return content;
  } else {
    return <h1>Please reload the page</h1>;
  }
}

export default BannerInfoAlt;
