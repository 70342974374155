import Axios from "axios";
import { useContext, useEffect, useState } from "react";
import CreateApiUrl from "../Context/ApiUrlCTX";

function useApiPages(state) {
  const { APIURL } = useContext(CreateApiUrl);

  const [isPagesApi, setPagesApi] = useState([]);
  const [images, setImages] = useState([]);

  const [isLoadedImages, setIsLoadedImages] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isErr, setIsErr] = useState(false);

  const PagesApi = `${APIURL}/wp-json/wp/v2/pages/${state}`;

  useEffect(() => {
    Axios.get(PagesApi)
      .then((res) => {
        setPagesApi(res.data);
        setIsLoaded(true);
      })
      .catch((err) => {
        setIsErr(true);
        setIsLoaded(true);
      });
  }, [PagesApi]);

  useEffect(() => {
    if (isPagesApi.length === 0) return;
    const ImagesApi = `${APIURL}/wp-json/wp/v2/media/${isPagesApi?.featured_media}`; //http://localhost/qhamaDev/wp-json/wp/v2/media/37
    Axios.get(ImagesApi)
      .then(setIsLoadedImages(true))
      .then((res) => {
        setImages(res.data);
        setIsLoadedImages(false);
      })
      .catch((err) => {
        setIsLoadedImages(true);
      });
  }, [isPagesApi, APIURL]);

  return { images, isPagesApi, isLoaded, isLoadedImages, isErr };
}

export default useApiPages;
