import React from "react";
import { Link } from "react-router-dom";
import BadGateway from "../../modules/Errors/BadGateway";
import "./fetch/data.json";
import SignupModal from "../../modules/QuickLinksData/SignupModal";
import { ListItem, ListItemText } from "@mui/material";
import { useState } from "react";

function NavList({
  formModalHandleClick,
  formModalRemoveHandleClick,
  isFormModalon,
  setisNavShowing,
  seticonChange,
  images,
  isLoaded,
  isErr,
}) {
  const [submenu, setSubmenu] = useState(false);
  const [activeSubmenu, setactiveSubmenu] = useState(0);

  const submenuFunc = (i) => {
    setSubmenu(!submenu);
  };

  const listAmenitiesChildA = images.map(function (images) {
    const checkNav = images.post_parent === 53;
    const { object_id } = images;
    const la = images.title.toLowerCase().replace(/\s+/g, "");
    if (checkNav) {
      return (
        <Link
          key={images.title}
          to={{
            pathname: `/about-us/${la}`,
            state: { prevPath: object_id },
          }}
          // className="hover:text-white text-[#ed1c24] block"
        >
          {/* <li
            className={`${images.post_parent} m-0 relative px-[30px] py-[15px] hover:bg-black bg-opacity-50 group block uppercase font-sans font-bold text-center`}
          >
            {images.title.replace(/([#-;]+)/g, " ")}
          </li> */}
          <ListItem button>
            <ListItemText
              primary={images.title.replace(/([#-;]+)/g, " ").toUpperCase()}
            />
          </ListItem>
        </Link>
      );
    }
    return null;
  });

  const listAmenitiesChildB = images.map(function (images) {
    const checkNav = images.post_parent === 62;
    const { object_id } = images;
    if (checkNav) {
      return (
        <Link
          key={images.title}
          to={{
            pathname: `/our-team/${images.title
              .toLowerCase()
              .replace(/\s+/g, "")
              .replace(/([#-;]+)/g, "")}`,
            state: { prevPath: object_id },
          }}
          // className="hover:text-white text-[#ed1c24] block"
        >
          {/* <li
            className={`${images.post_parent} m-0 relative px-[30px] py-[15px] group block hover:bg-black bg-opacity-50 uppercase font-sans font-bold text-center`}
          >
            {images.title.replace(/([#-;]+)/g, " & ")}
          </li> */}
          <ListItem button>
            <ListItemText
              primary={images.title.replace(/([#-;]+)/g, " ").toUpperCase()}
            />
          </ListItem>
        </Link>
      );
    }
    return null;
  });

  const listAmenitiesChildC = images.map(function (images, i) {
    const checkNav = images.post_parent === 68;
    const { object_id } = images;
    if (checkNav) {
      return (
        <Link
          key={i}
          to={{
            pathname: `/home/${images.title.toLowerCase().replace(/\s+/g, "")}`,
            state: { prevPath: object_id },
          }}
        >
          {/* <li
            className={`${images.post_parent} m-0 relative px-[30px] py-[15px] group block hover:bg-black bg-opacity-50 uppercase font-sans font-bold text-center`}
          >
            {images.title.replace(/([#-;]+)/g, " ")}
          </li> */}
          <ListItem button>
            <ListItemText
              primary={images.title.replace(/([#-;]+)/g, " ").toUpperCase()}
            />
          </ListItem>
        </Link>
      );
    }
    return null;
  });

  const listAmenitiesParent = images.map(function (images, i) {
    const Navlist = images.title;
    const { object_id } = images;

    const checkNav1 = images.post_parent === 0;

    const checkNav = images.object_id === "53";
    const checkNav2 = images.object_id === "62";
    const checkNav3 = images.object_id === "68";
    if (checkNav) {
      return (
        <div key={images.title} className="relative">
          <li
            onClick={() => {
              submenuFunc();
              setactiveSubmenu(i);
            }}
            className={`${images.post_parent} ${
              submenu && activeSubmenu !== i ? "blur-sm" : ""
            } ${
              submenu && activeSubmenu === i
                ? "hover:bg-[#ed1c24] text-white lg:bg-[#ed1c24]"
                : "hover:bg-opacity-[0.04] text-[#ed1c24]"
            } m-0 relative group block py-[8px] px-[16px]  hover:bg-black uppercase font-sans font-bold text-center`}
          >
            {Navlist}
          </li>
          {submenu && activeSubmenu === i ? (
            <ul className="relative lg:absolute w-full lg:w-auto top-[71px] z-10 bg-slate-50 drop-shadow-2xl right-0 translate-x-0 lg:translate-x-full lg:top-0">
              {listAmenitiesChildA}
            </ul>
          ) : null}
        </div>
      );
    } else if (checkNav2) {
      return (
        <div key={i} className="relative">
          <li
            key={images.title}
            onClick={() => {
              submenuFunc();
              setactiveSubmenu(i);
            }}
            className={`${images.post_parent} ${
              submenu && activeSubmenu !== i ? "blur-sm" : ""
            } ${
              submenu && activeSubmenu === i
                ? "hover:bg-[#ed1c24] text-white lg:bg-[#ed1c24]"
                : "hover:bg-opacity-[0.04] text-[#ed1c24]"
            } m-0 relative group block py-[8px] px-[16px]  hover:bg-black uppercase font-sans font-bold text-center`}
          >
            {Navlist}
          </li>
          {submenu && activeSubmenu === i ? (
            <ul className="relative lg:absolute w-full lg:w-auto top-[51px] z-10 bg-slate-50 drop-shadow-2xl right-0 translate-x-[100%] lg:top-0">
              {listAmenitiesChildB}
            </ul>
          ) : null}
        </div>
      );
    } else if (checkNav3) {
      return (
        <div key={i} className="relative">
          <li
            key={images.title}
            onClick={() => {
              submenuFunc();
              setactiveSubmenu(i);
            }}
            className={`${images.post_parent} ${
              submenu && activeSubmenu !== i ? "blur-sm" : ""
            } ${
              submenu && activeSubmenu === i
                ? "hover:bg-[#ed1c24] text-white lg:bg-[#ed1c24]"
                : "hover:bg-opacity-[0.04] text-[#ed1c24]"
            } m-0 relative group block py-[8px] px-[16px]  hover:bg-black uppercase font-sans font-bold text-center`}
          >
            {Navlist}
          </li>
          {submenu && activeSubmenu === i ? (
            <ul className="relative lg:absolute w-full lg:w-auto top-[51px] z-10 bg-slate-50 drop-shadow-2xl right-0 translate-x-[100%] lg:top-0">
              {listAmenitiesChildC.reverse()}
            </ul>
          ) : null}
        </div>
      );
    } else if (checkNav1) {
      var NoChildParent =
        images.ID === 95 ? (
          // <li
          //   key={images.title}
          //   onClick={formModalHandleClick}
          //   className={`${images.post_parent} m-0 relative px-[30px] py-[15px] block text-[#ed1c24] hover:bg-black bg-opacity-50 uppercase font-sans font-bold text-center`}
          // >
          //   {images.title.replace(/([#-;]+)/g, " & ")}
          // </li>
          <ListItem
            key={images.title}
            button
            onClick={formModalHandleClick}
            className={`${submenu ? "blur-sm" : ""}`}
          >
            <ListItemText
              primary={images.title.replace(/([#-;]+)/g, " ").toUpperCase()}
            />
          </ListItem>
        ) : (
          <Link
            key={images.title}
            to={{
              pathname: `/home/${images.title
                .toLowerCase()
                .replace(/\s+/g, "")
                .replace(/([#-;]+)/g, "")}`,
              state: { prevPath: object_id },
            }}
            className={`${submenu ? "blur-sm" : ""}`}
          >
            {/* <li
              className={`${images.post_parent} m-0 relative px-[30px] py-[15px] block text-[#ed1c24] hover:bg-black bg-opacity-50 uppercase font-sans font-bold text-center`}
            >
              {images.title.replace(/([#-;]+)/g, " & ")}
            </li> */}
            <ListItem
              key={images.title}
              button
              className={`${submenu ? "blur-sm" : ""}`}
            >
              <ListItemText
                primary={images.title.replace(/([#-;]+)/g, " ").toUpperCase()}
              />
            </ListItem>
          </Link>
        );
      return NoChildParent;
    }
    return NoChildParent;
  });

  if (!isLoaded) {
    return (
      <div className="loader" style={{ background: "transparent" }}>
        <span style={{ backgroundColor: "#ed1c24" }} />
        <span style={{ backgroundColor: "#ed1c24" }} />
        <span style={{ backgroundColor: "#ed1c24" }} />
      </div>
    );
  } else if (isErr === true) {
    return <BadGateway />;
  } else {
    return (
      <>
        {isFormModalon ? (
          <SignupModal
            formModalRemoveHandleClick={formModalRemoveHandleClick}
            setisNavShowing={setisNavShowing}
            seticonChange={seticonChange}
          />
        ) : null}
        <ul className={`opacity-100 block p-0 w-full`}>
          {listAmenitiesParent}
        </ul>
      </>
    );
  }
}

export default NavList;
