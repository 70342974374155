import React, { useContext, useEffect, useState } from "react";
import Slider from "react-slick";
import axios from "axios";
import BadGateway from "../Errors/BadGateway";
import ChevronLeft from "../../icons/chevron-left.svg";
import ChevronRight from "../../icons/chevron-right.svg";
import CreateApiUrl from "../../Context/ApiUrlCTX";

function AmenitiesSlider() {
  const { APIURL } = useContext(CreateApiUrl);
  const ImagesApi = `${APIURL}/?rest_route=/wp/v2/amenity&_embed`;

  function NextArrow(props) {
    const { style, onClick } = props;
    return (
      <div
        className={"SliderBTN SliderBTNNext"}
        style={{ ...style, left: "unset", right: "0" }}
        onClick={onClick}
      >
        <img src={ChevronRight} alt="" />
      </div>
    );
  }

  function PrevArrow(props) {
    const { style, onClick } = props;
    return (
      <div
        className={"SliderBTN SliderBTNPrev"}
        style={{ ...style }}
        onClick={onClick}
      >
        <img src={ChevronLeft} alt="" />
      </div>
    );
  }

  const settings = {
    dots: false,
    centerMode: true,
    infinite: true,
    autoplay: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  const [images, setImages] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isErr, setIsErr] = useState(false);

  useEffect(() => {
    axios
      .get(ImagesApi)
      .then((res) => {
        setImages(res.data);
        setIsLoaded(true);
      })
      .catch((err) => {
        setIsErr(true);
        setIsLoaded(true);
        console.log(err);
      });
  }, [ImagesApi]);

  const listAmenities = images.map((images) => (
    <div key={images.id} className="content-room-info amenities">
      <img
        alt="Port Elizabeth"
        src={images._embedded["wp:featuredmedia"][0].source_url}
        className="w-full h-full object-cover"
      />

      <figure className="anime-1 w-full top-0 m-0 py-[25px] px-[30px] absolute left-0 h-full bg-black bg-opacity-70 box-border grid align-center">
        <div className="z-0 text-white drop-shadow-md m-auto">
          <h3
            style={{
              backgroundColor: "unset",
              padding: "0",
              borderRadius: "0",
            }}
          >
            {images.title.rendered}
          </h3>
        </div>
      </figure>
    </div>
  ));

  if (!isLoaded) {
    return (
      <div
        className="loader"
        style={{ background: "transparent", height: "100px" }}
      >
        <span />
        <span />
        <span />
      </div>
    );
  } else if (isErr === true) {
    return <BadGateway />;
  } else {
    return (
      <>
        <React.StrictMode>
          <Slider {...settings}>{listAmenities}</Slider>
        </React.StrictMode>
      </>
    );
  }
}

export default AmenitiesSlider;
