import React from "react";

function Select({ children, name, id, className, onChange }) {
  return (
    <select onChange={onChange} name={name} id={id} className={className}>
      {children}
    </select>
  );
}

export default Select;
