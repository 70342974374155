import React from "react";
import ReactDOM from "react-dom";
import ContentHeader from "../ContentHeader";
import FormUnit from "../FormUnit";
import CloseIcon from "@mui/icons-material/Close";

function SignupModal({
  formModalRemoveHandleClick,
  isFormModalon,
  setisNavShowing,
  seticonChange,
}) {
  setisNavShowing ? setisNavShowing(false) : setisNavShowing(true);
  seticonChange ? seticonChange(false) : seticonChange(true);
  return ReactDOM.createPortal(
    <div
      className={
        "animate__animated animate__backInUp fixed w-screen h-screen z-[9999999]"
      }
      style={{
        backgroundSize: "cover",
      }}
    >
      <div
        className={
          "fixed w-full h-full bg-gradient-to-r from-[#960a10] to-[#c30b49] backdrop-blur-sm bg-opacity-40 overflow-hidden"
        }
        style={{
          opacity: 0.8,
        }}
      />
      <div
        className={
          "grids relative w-full h-full lg:h-auto lg:w-[70%] m-auto bg-white overflow-auto top-1/2 -translate-y-1/2 drop-shadow-lg"
        }
      >
        <div
          onClick={() => {
            formModalRemoveHandleClick();
          }}
          className="p-[10px] text-white bg-gray-800 absolute right-0 top-0 cursor-pointer z-10"
        >
          <CloseIcon color="inherit" />
        </div>
        <div
          className={
            "content-signup-modals gap-5 lg:gap-0 grid grid-cols-1 lg:grid-cols-2"
          }
        >
          <section
            style={{
              backgroundImage: "url(/images/rooms/SteveBikoPrecinct-64.jpg)",
              backgroundSize: "cover",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "0",
                left: "0",
                zIndex: "0",
                background:
                  "linear-gradient( 90deg, #ed1c24 0%, #c30b49 100% )",
              }}
              className={"bg-ol signup-modal-bg"}
            />
            <div className="p-4 gap-4 relative grid h-4/5">
              <ContentHeader
                style={{
                  color: "#fff",
                  zIndex: "1",
                  position: "inherit",
                  padding: "20px",
                  margin: "0",
                }}
              >
                Live - Love - Learn @Steve Biko Precinct
              </ContentHeader>
              <div
                className={
                  "grid grid-cols-1 h-auto lg:h-[400px] aspect-video lg:aspect-auto"
                }
              >
                <iframe
                  width="100%"
                  height="100%"
                  src="https://www.youtube-nocookie.com/embed/5e0xGSG3Q74?controls=0"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                  className=" w-full h-full object-cover"
                ></iframe>
              </div>
            </div>
          </section>
          <section>
            <div className="p-4">
              <ContentHeader className="text-[#ed1c24] lg:text-gray-700 text-xl py-0 pb-5 m-0">
                Unit Sign up form
              </ContentHeader>
              <FormUnit />
            </div>
          </section>
        </div>
      </div>
    </div>,
    document.getElementById("modal")
  );
}

export default SignupModal;
