import React from "react";
import BadGateway from "../modules/Errors/BadGateway";
import useApiPages from "../Utilities/useApiPages";
import { useLocation } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";

function Management({ AnimeCSS }) {
  const { state } = useLocation();

  const pages = useApiPages(state?.prevPath);

  if (!pages?.isLoaded) {
    return (
      <div className="loader">
        <span />
        <span />
        <span />
      </div>
    );
  } else if (pages?.isErr === true) {
    return <BadGateway />;
  } else {
    return (
      <div className={AnimeCSS()} style={{ overflow: "hidden" }}>
        <section className="banner">
          <div className="gd"></div>
          <AnimatePresence initial={{ opacity: 0 }}>
            {pages?.isLoadedImages ? null : (
              <motion.img
                initial={{ opacity: 0, scale: 1.5 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 1.5 }}
                src={pages?.images?.source_url}
                className="w-full h-full object-cover"
                alt=""
              />
            )}
          </AnimatePresence>

          <figure className="banner-text-reg left">
            <div className="banner-price-text-reg">
              <h1>{pages?.isPagesApi.title.rendered}</h1>
            </div>
          </figure>
        </section>

        <section className="bg-3 ct">
          <div
            className="side-featured-images post-174 page type-page status-publish has-post-thumbnail hentry"
            style={{ display: "block" }}
          >
            <section
              className="about-content-text fill-width"
              dangerouslySetInnerHTML={{
                __html: pages?.isPagesApi.content.rendered,
              }}
            />
          </div>
        </section>
      </div>
    );
  }
}

export default Management;
