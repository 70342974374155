import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SteveBikoPrecinctSlider from "../modules/DataSlider/SteveBikoPrecinctSlider";
import RecentPost from "../AppComponents/RecentPost/RecentPost";
import ContentHeader from "../modules/ContentHeader";
import BannerInfoAlt from "../modules/BannerInfoAlt";
import Button from "../modules/ui/button/button";
import Axios from "axios";
import CreateApiUrlContext from "../Context/ApiUrlCTX";

function SteveBikoPrecinct({
  AnimeCSS,
  formModalHandleClick,
  formModalRemoveHandleClick,
  isFormModalon,
  openVideo,
}) {
  const [stevebikoprecinct, setStevebikoprecinct] = useState([]);
  const [ctaHeader, setCtaHeader] = useState([]);

  const { APIURL } = useContext(CreateApiUrlContext);
  const ImagesApi = `${APIURL}/wp-json/wp/v2/pages?per_page=23&_embed`;
  const ctaHeaderApi = `${APIURL}/wp-json/wp/?rest_route=/wp/v2/cta&_embed`;

  const formatPrice = (price) => {
    const newPriceFormat = new Intl.NumberFormat().format(price);
    return newPriceFormat;
  };

  useEffect(() => {
    Axios.get(ImagesApi)
      .then((res) => {
        setStevebikoprecinct(res.data);
      })
      .catch((err) => {});
    Axios.get(ctaHeaderApi)
      .then((res) => {
        setCtaHeader(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [ImagesApi, ctaHeaderApi]);

  const checkImage = () => {
    if (stevebikoprecinct[6]?.featured_media === 0) return;
    return stevebikoprecinct[6]?._embedded["wp:featuredmedia"][0]?.source_url;
  };

  if (stevebikoprecinct?.featured_media === 0) return;
  return (
    <div className={AnimeCSS()} style={{ overflow: "hidden" }}>
      <section className="banner">
        <div className="gd" />
        <img
          width="950"
          height="534"
          src={checkImage()}
          className="h-full w-full object-cover"
          alt=""
        />
        {ctaHeader.map((cat, i) => {
          return (
            <div key={i}>
              <BannerInfoAlt
                isFormModalon={isFormModalon}
                formModalHandleClick={formModalHandleClick}
                formModalRemoveHandleClick={formModalRemoveHandleClick}
              >
                <div
                  dangerouslySetInnerHTML={{ __html: cat.content.rendered }}
                />
                <h4>FROM</h4>
                <p>
                  R{formatPrice(cat.acf.unit_prices)}
                  <em>P/M</em>
                </p>
                <Button
                  onClick={formModalHandleClick}
                  buttonStyle="btn--gradient  p-5 w-full"
                  type="button"
                >
                  Sign Up
                </Button>
              </BannerInfoAlt>
            </div>
          );
        })}

        <div className="hidden lg:block absolute z-10 bottom-[5%] left-[4%] lg:bottom-[15%] lg:left-[4%]">
          <Button
            onClick={() => openVideo()}
            buttonStyle="btn--gradient--play"
            type="button"
          >
            play video
          </Button>
        </div>
      </section>

      <section
        className="block-qoute"
        style={{
          backgroundImage: "unset",
          border: "none",
          display: "flex",
          backgroundColor: "black",
          color: "#fff",
        }}
      >
        <div className="block-qoute-text pb-5">
          <div className="">
            <div className="textwidget">
              <ContentHeader className={"text-white text-2xl"}>
                Steve Biko <i>Precinct</i>
              </ContentHeader>
              <p>
                Qhama is a fully accredited and{" "}
                <Link to="#">female lead non-profit company</Link> established
                as a special purpose vehicle for social housing and related
                housing instruments.{" "}
                <Link to="#">The company is 100% black owned</Link>.<br></br>
                <br></br>
                Qhama was established in October 2013 and in February 2014 it
                was accredited by the{" "}
                <Link to="#">
                  Social Housing Regulatory Authority (SHRA)
                </Link>{" "}
                as a Social Housing Institute. The company is based in Port
                Elizabeth and has satellite office in East London, Eastern Cape
              </p>

              <Button
                buttonStyle={
                  "w-full float-left mb-2 block pb-5 lg:w-auto btn--defualt btn--gradient"
                }
                type="button"
              >
                <Link to="/AboutSteveBiko">Learn More</Link>
              </Button>
            </div>
          </div>
        </div>
      </section>

      <section className="content-rooms bg-2">
        <div className="bg-ol">
          <div className="wrapper-content-rooms">
            <ContentHeader className={"text-white text-xl"}>
              <em>EXPLORE</em> <i>WHAT WE OFFER</i>
            </ContentHeader>
            <SteveBikoPrecinctSlider />
          </div>
        </div>
      </section>

      <RecentPost />
    </div>
  );
}

export default SteveBikoPrecinct;
