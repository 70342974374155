import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

function BackToTopBTN() {
  const [showBackToTop, setShowBackToTop] = useState(false);

  useEffect(() => {
    window.onscroll = function () {
      ab();
    };

    function ab() {
      return window.scrollY > window.outerHeight / 2
        ? setShowBackToTop(true)
        : setShowBackToTop(false);
    }
  }, [showBackToTop]);

  return ReactDOM.createPortal(
    <div
      onClick={() => {
        window.scrollTo(0, 0);
      }}
      className={`${
        showBackToTop ? ClassNames.on : ClassNames.off
      } items-center flex content-center fixed w-[50px] h-[50px] rounded-full bottom-10 lg:bottom-[67px] top-auto left-1/2 -translate-x-1/2 -translate-y-[30%] scale-95 lg:scle-[0.8] z-[99]`}
      style={{ zIndex: 999 }}
    >
      <ExpandLessIcon
        className="text-white translate-y-[2px] m-auto"
        fontSize="large"
      />
    </div>,
    document.getElementById("modal")
  );
}

const ClassNames = {
  on: "SliderBTN BackToTopBTN",
  off: "SliderBTN BackToTopBTNoff",
};

export default BackToTopBTN;
