import React from "react";

function BannerInfoTitle(props) {
  return (
    <figure className="banner-text-reg left">
      <div className="banner-price-text-reg">
        <h1>{props.children}</h1>
      </div>
    </figure>
  );
}

export default BannerInfoTitle;
