import React from "react";

function FormButton({ style, type, value, className, onClick }) {
  return (
    <button
      style={style}
      type={type}
      value={value}
      className={className}
      onClick={onClick}
    >
      {value}
    </button>
  );
}

export default FormButton;
