import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import BadGateway from "../Errors/BadGateway";
import { motion } from "framer-motion";
import CreateApiUrl from "../../Context/ApiUrlCTX";

function AmenitiesBannerSlider({ deviceWidth }) {
  const [images, setImages] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isErr, setIsErr] = useState(false);

  const { APIURL } = useContext(CreateApiUrl);
  const ImagesApi = `${APIURL}/?rest_route=/wp/v2/amenity&_embed`;

  useEffect(() => {
    axios
      .get(ImagesApi)
      .then((res) => {
        setImages(res.data);
        setIsLoaded(true);
      })
      .catch((err) => {
        setIsErr(true);
        setIsLoaded(true);
        console.log(err);
      });
  }, [ImagesApi]);

  const [activeSlide, setactiveSlide] = useState(0);

  function runSlider() {
    setTimeout(() => {
      if (images.length - 1 === activeSlide) {
        setactiveSlide(0);
      } else if (activeSlide >= 0) {
        setactiveSlide(activeSlide + 1);
      }
    }, 5000);
  }

  runSlider();

  const AmenitiesBannerSlider = images.map((images, i) => {
    if (images.featured_media === 0) return null;
    return (
      <motion.div
        key={images.id}
        initial={{ opcity: 0.5 }}
        animate={{
          opacity: 1,
          left: `${(i - activeSlide) * deviceWidth || 0}px`,
        }}
        exit={{ opacity: 0.5 }}
        transition={{ type: "spring", damping: 20, stiffness: 100 }}
        className="w-full h-full absolute"
      >
        <img
          alt=""
          src={images._embedded["wp:featuredmedia"][0].source_url}
          className="w-full h-full object-cover"
        />
      </motion.div>
    );
  });

  if (!isLoaded) {
    return (
      <div className="loader">
        <span />
        <span />
        <span />
      </div>
    );
  } else if (isErr === true) {
    return <BadGateway />;
  } else {
    return AmenitiesBannerSlider;
  }
}

export default AmenitiesBannerSlider;
