import Axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CreateApiUrl from "../Context/ApiUrlCTX";

function FacilitiesImageCard() {
  const { APIURL } = useContext(CreateApiUrl);
  const ImagesApi = `${APIURL}/?rest_route=/wp/v2/facilities&_embed`;

  const [facilitiesCard, setFacilitiesCard] = useState([]);

  useEffect(() => {
    Axios.get(ImagesApi)
      .then((res) => {
        setFacilitiesCard(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [ImagesApi]);

  const checkImage = (i) => {
    if (facilitiesCard[i]?.featured_media === 0) return;
    return facilitiesCard[i]?._embedded["wp:featuredmedia"][0]?.source_url;
  };

  const content = facilitiesCard.map((FacilitiesContent, i) => {
    const { object_id } = FacilitiesContent;
    return (
      <div
        key={i}
        id="post-417"
        className="w-full h-[40vh] relative overflow-hidden drop-shadow-lg lg:border-l-[6px] border-[#c00f0e] box-border"
      >
        <Link
          to={{
            pathname: `/home/${FacilitiesContent.title.rendered
              .toLowerCase()
              .replaceAll(" ", "")}`,
            state: { prevPath: 72 },
          }}
        >
          <figure
            className="m-0 absolute group cursor-pointer left-0 h-full w-full bg-slate-300 box-border"
            style={{ padding: "0", top: "0" }}
          >
            <li className="p-0 top-0 w-full h-full relative">
              <h1 className="z-20 text-[30px] pt-[30px] m-0 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                {FacilitiesContent.title.rendered}
              </h1>
              <img
                src={checkImage(i)}
                className="w-full h-full object-cover"
                alt={FacilitiesContent.title.rendered}
              />
            </li>
            <div className="bg-black group-hover:opacity-40 opacity-0 w-full h-full absolute top-0 z-10"></div>
          </figure>
        </Link>
      </div>
    );
  });

  return content.reverse();
}

export default FacilitiesImageCard;
