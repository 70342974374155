import React from "react";
import BannerInfoTitle from "../../modules/BannerInfoTitle";
import RecentPost from "../../AppComponents/RecentPost/RecentPost";
import useApiPages from "../../Utilities/useApiPages";
import { useLocation } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";

function PressRelease(props) {
  const { state } = useLocation();

  const Pages = useApiPages(state?.prevPath);

  return (
    <div className={props.AnimeCSS()} style={{ overflow: "hidden" }}>
      <section className="banner" style={{ height: "45vh" }}>
        <div className="gd" />
        <AnimatePresence initial={{ opacity: 0 }}>
          {Pages?.isLoadedImages ? null : (
            <motion.img
              initial={{ opacity: 0, scale: 1.5 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 1.5 }}
              src={Pages?.images?.source_url}
              className="w-full h-full object-cover"
              alt=""
            />
          )}
        </AnimatePresence>
        <BannerInfoTitle>Press Release</BannerInfoTitle>
      </section>

      <section className="bg-3 ct">
        <div
          id="post-162"
          className="side-featured-images"
          style={{ display: "block" }}
        >
          {!Pages?.isLoaded ? (
            <section className="about-content-text fill-width">
              <div
                style={{
                  height: "100%",
                  backgroundImage: "none",
                }}
                className="loader"
              >
                <span
                  style={{
                    backgroundColor: "#ed1c24",
                  }}
                />
                <span
                  style={{
                    backgroundColor: "#ed1c24",
                  }}
                />
                <span
                  style={{
                    backgroundColor: "#ed1c24",
                  }}
                />
              </div>
            </section>
          ) : (
            <section className="about-content-text fill-width">
              <div
                className=""
                dangerouslySetInnerHTML={{
                  __html: Pages?.isPagesApi?.content?.rendered,
                }}
              />
            </section>
          )}
        </div>
      </section>

      <RecentPost />
    </div>
  );
}

export default PressRelease;
