import React, { useContext, useEffect, useState } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import ChevronLeft from "../../icons/chevron-left.svg";
import ChevronRight from "../../icons/chevron-right.svg";
import Button from "../ui/button/button";
import Axios from "axios";
import CreateApiUrl from "../../Context/ApiUrlCTX";

function SteveBikoPrecinctSlider() {
  const { APIURL } = useContext(CreateApiUrl);
  const sliderExploreApi = `${APIURL}/?rest_route=/wp/v2/amenities_slider&_embed`;

  const [sliderExplore, setsliderExplore] = useState([]);

  const formatPrice = (price) => {
    const newPriceFormat = new Intl.NumberFormat().format(price);
    return newPriceFormat;
  };

  useEffect(() => {
    Axios.get(sliderExploreApi)
      .then((res) => {
        setsliderExplore(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [sliderExploreApi]);

  function NextArrow(props) {
    const { style, onClick } = props;
    return (
      <div
        className={"SliderBTN SliderBTNNext"}
        style={{ ...style, left: "unset", right: "0" }}
        onClick={onClick}
      >
        <img src={ChevronRight} alt="" />
      </div>
    );
  }

  function PrevArrow(props) {
    const { style, onClick } = props;
    return (
      <div
        className={"SliderBTN SliderBTNPrev"}
        style={{ ...style }}
        onClick={onClick}
      >
        <img src={ChevronLeft} alt="" />
      </div>
    );
  }

  const settings = {
    dots: false,
    centerMode: true,
    infinite: true,
    autoplay: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  const listItems = sliderExplore.map((arTest, i) => {
    if (arTest.featured_media === 0) return null;
    return (
      <div
        key={i}
        className="amenitie w-full h-[40vh] m-0 relative border-l-[5px] border-white overflow-hidden drop-shadow-lg box-border"
      >
        <img
          alt={arTest.title.rendered}
          src={arTest._embedded["wp:featuredmedia"][0].source_url}
          className="w-full h-full object-cover"
          style={{ animation: "pulse2 3s infinite alternate" }}
        />

        <figure className="anime-1 w-full top-0 m-0 py-[25px] px-[30px] absolute left-0 h-full bg-black bg-opacity-70 box-border grid align-center">
          <div className="z-0 text-white drop-shadow-md m-auto">
            <div>
              {arTest.title.rendered === "amenities" ? (
                <h3
                  style={{
                    backgroundColor: "unset",
                    padding: "0",
                    borderRadius: "0",
                  }}
                  className="m-0 text-center uppercase text-[2.5em] lg:text-[3.5em] font-bold font-sans"
                >
                  {arTest.title.rendered}
                </h3>
              ) : (
                <>
                  <h4
                    style={{
                      backgroundColor: "unset",
                      padding: "0",
                      borderRadius: "0",
                      marginBottom: "0",
                    }}
                    className="m-0 mt-[10px] text-[21px] align-super font-normal text-white uppercase font-sans"
                  >
                    from
                  </h4>
                  <h3
                    style={{
                      backgroundColor: "unset",
                      padding: "0",
                      borderRadius: "0",
                    }}
                    className="m-0 text-center uppercase text-[3.5em] font-bold font-sans"
                  >
                    R{formatPrice(arTest.title.rendered)}
                    <em className="p-[10px] rounded-full text-[16px] ml-2 align-super bg-gradient-to-r from-[#ed1c24] to-[#c30b49]">
                      p/m
                    </em>
                  </h3>
                </>
              )}
            </div>
            <Button
              buttonStyle="block bg-[#ed1c24] w-full text-white rounded-full py-2 px-5 text-center"
              type="button"
            >
              <Link
                to={
                  arTest.title.rendered === "amenities"
                    ? `/home/${arTest.title.rendered}`
                    : `/Rooms`
                }
                className="text-white"
              >
                Learn More
              </Link>
            </Button>
          </div>
        </figure>
      </div>
    );
  });

  return <Slider {...settings}>{listItems.reverse()}</Slider>;
}

export default SteveBikoPrecinctSlider;
