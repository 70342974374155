import React from "react";
import ContentHeader from "./ContentHeader";
import { Link } from "react-router-dom";
import Button from "./ui/button/button";
import Downloads from "./QuickLinksData/Downloads";

import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import BusinessIcon from "@mui/icons-material/Business";

function QuickLinks({ OpenModal, CloseModal, isModalon }) {
  const QuickLinksData = [
    {
      item: {
        title: (
          <p className="inline-flex text-center rounded-full drop-shadow-lg bg-gradient-to-r from-[#ed1c24] to-[#c30b49] text-white  hover:from-white hover:to-white cursor-pointer py-3 px-8 hover:text-[#ed1c24]">
            ACCREDITATIONS
          </p>
        ),
        icon: <EmojiEventsIcon fontSize="inherit" />,
        link: "/Accreditation",
        ID: 0,
      },
    },
    {
      item: {
        title: (
          <Link
            to={{
              pathname: `/press-release`,
              state: { prevPath: 303 },
            }}
          >
            <p className="inline-flex text-center rounded-full drop-shadow-lg bg-gradient-to-r from-[#ed1c24] to-[#c30b49] text-white  hover:from-white hover:to-white cursor-pointer py-3 px-8 hover:text-[#ed1c24]">
              PRESS - RELEASE
            </p>
          </Link>
        ),
        icon: <NewspaperIcon fontSize="inherit" />,
        link: "/PressRelease",
        ID: 1,
      },
    },
    {
      item: {
        title: (
          <p className="inline-flex text-center rounded-full drop-shadow-lg bg-gradient-to-r from-[#ed1c24] to-[#c30b49] text-white hover:from-white hover:to-white py-3 px-8 hover:text-[#ed1c24]">
            DOWNLOADS
          </p>
        ),
        icon: <CloudDownloadIcon fontSize="inherit" />,
        link: "",
        ID: 2,
      },
    },
    {
      item: {
        title: (
          <p className="inline-flex text-center rounded-full drop-shadow-lg bg-gradient-to-r from-[#ed1c24] to-[#c30b49] text-white  hover:from-white hover:to-white cursor-pointer py-3 px-8 hover:text-[#ed1c24]">
            ADDRESS
          </p>
        ),
        icon: <BusinessIcon fontSize="inherit" />,
        link: "/home/contactUs",
        ID: 3,
      },
    },
  ];

  const QuickLinks = QuickLinksData.map(function (content, index) {
    const data = (
      <li className="anime-2 w-[140px] group inline-table hover:bg-black box-content">
        <span className={`text-8xl`}>{content.item.icon}</span>
        {content.item.title}
      </li>
    );

    return content.item.ID === 2 ? (
      <div
        onClick={() => {
          OpenModal();
        }}
        key={index}
      >
        {data}
      </div>
    ) : (
      <Link key={content.item.ID} to={content.item.link}>
        {data}
      </Link>
    );
  });

  return (
    <>
      {isModalon ? (
        <Downloads CloseModal={CloseModal} isModalon={isModalon}>
          <h1>Downloads</h1>
          <div className="downloadList">
            <p>Steve Biko Brochure</p>
            <Button type="button">
              Download
              <i className="icon icon-download modalCloseIcon" />
            </Button>
            <p>Qhama Brochure</p>
            <Button type="button">
              Download
              <i className="icon icon-download modalCloseIcon" />
            </Button>
          </div>
        </Downloads>
      ) : null}

      <section className="bg-white bottom-1 border-[#c82b29]">
        <div className="">
          <div className="bg-hero-pattern h-full opacity-30 w-full absolute bottom-0 z-0" />
          <div className="">
            <ContentHeader className={"text-[#cb1c1e] text-3xl"}>
              QUICK LINKS
            </ContentHeader>
            <div className="">
              <ul className="p-5 lg:w-4/5 m-auto flex lg:justify-around box-border pt-0 overflow-x-auto w-full justify-start">
                {QuickLinks}
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default QuickLinks;
