import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { AnimatePresence, motion } from "framer-motion";
import SearchIcon from "@mui/icons-material/Search";
import ContentHeader from "../ContentHeader";
import { Link } from "react-router-dom";
import Button from "../ui/button/button";
import { Alert } from "@mui/material";

function SearchResults(props, ref) {
  const {
    searchData,
    setSearchMsg,
    searchQuery,
    ContentFade,
    searchMsg,
    isLoading,
    setsearchData,
    noResults,
    setnoResults,
    setsearchItemsReady,
    searchItemsReady,
    searchRan,
    formWidth,
    formRefCheck,
    searchIconWidth,
    closeAllSearch,
  } = props;

  const formatPrice = (price) => {
    const newPriceFormat = new Intl.NumberFormat();
    return newPriceFormat.format(price);
  };

  useEffect(() => {
    const filter = searchData.filter(
      (item) =>
        item?.title?.rendered
          ?.toLowerCase()
          .includes(searchQuery?.search?.toLowerCase()) ||
        item?.unit_price
          ?.toLowerCase()
          .includes(searchQuery?.search?.toLowerCase()) ||
        item?.apartment_view
          ?.toLowerCase()
          .includes(searchQuery?.search?.toLowerCase())
    );
    setsearchData(() => {
      return filter;
    });

    if (filter.length === 0) {
      setnoResults(true);
      setsearchItemsReady(false);
    } else {
      setsearchItemsReady(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery?.search, searchData.length, setsearchData]);

  const searchDataMap = searchData.map((searchItem, i) => {
    const {
      title,
      unit_price,
      _embedded,
      apartment_view,
      slug,
      featured_media,
      id,
    } = searchItem;

    if (featured_media === 0) return null;

    return (
      <motion.div
        custom={i}
        initial={{ y: "-100%", opacity: 0 }}
        animate="visible"
        exit={{ y: "-100%", opacity: 0 }}
        variants={{
          visible: (i) => ({
            y: 0,
            opacity: 1,
            transition: {
              delay: i * 0.2,
            },
          }),
        }}
        ref={ref}
        key={i}
        className="text-white grid p-[10px] box-border bg-black bg-opacity-70 items-center"
        style={Styles.searchResultsContent}
      >
        <img
          // style={Styles.searchResultsImage}
          src={_embedded["wp:featuredmedia"][0].source_url}
          alt=""
          className="w-full flex-1 h-[100px] object-cover box-content bg-black border-2 border-white rounded-fulls m-auto"
        />
        <div className="pl-4 flex-1">
          <h1 className="text-left text-xl font-bold">
            {title.rendered}{" "}
            {apartment_view === "" ? null : (
              <span className="text-black text-sm bg-white py-2 px-4 rounded-full">
                {apartment_view}
              </span>
            )}
          </h1>
          <h1 className="text-left text-xl">R {formatPrice(unit_price)}</h1>

          <Button
            onClick={closeAllSearch}
            buttonStyle={
              "px-5 py-2 bg-[#ed1c24] hover:bg-black w-full lg:w-auto text-white mt-1 rounded-full"
            }
          >
            <Link
              key={i}
              role={"button"}
              className="text-white"
              to={{
                pathname: `/Rooms/${slug}`,
                state: { prevPath: id },
              }}
            >
              View
            </Link>
          </Button>
        </div>
      </motion.div>
    );
  });

  const formRefCheckWidth = formRefCheck?.current[0]?.clientWidth;

  return ReactDOM.createPortal(
    <motion.div
      // className={isSearchon ? "modalBG FadeON" : "modalBG"}
      initial={{ y: "-100%", opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: "-100%", opacity: 0 }}
      transition={{ delay: 0.5 }}
      className="fixed z-20 h-screen w-full lg:w-2/5 right-0 bg-[#ed1c24] bg-opacity-50 backdrop-blur-lg border-l-4 border-white box-border overflow-y-auto"
      style={{
        width:
          formWidth > 414 ? formRefCheckWidth + searchIconWidth + 4 : "100%",
      }}
    >
      <div
        className={` absolute z-10 w-full ${
          searchDataMap?.length > 0 ? "mt-16" : "h-full"
        }`}
      >
        {isLoading ? (
          <div className="h-full w-full grid items-center m-auto">
            <div className="flex items-center flex-col content-center m-auto px-5">
              <div className="loader" style={{ background: "transparent" }}>
                <span style={{ backgroundColor: "#fff" }} />
                <span style={{ backgroundColor: "#fff" }} />
                <span style={{ backgroundColor: "#fff" }} />
              </div>
            </div>
          </div>
        ) : (
          <>
            {searchDataMap?.length > 0 && searchItemsReady ? (
              <p className="text-center bg-[#ed1c24] text-white font-bold py-3">
                Search Results
              </p>
            ) : null}

            {searchDataMap?.length > 0 ? (
              searchItemsReady ? (
                <AnimatePresence initial={{ opacity: 0 }}>
                  {searchDataMap}
                </AnimatePresence>
              ) : null
            ) : noResults && searchRan && searchDataMap?.length === 0 ? (
              <div className="h-full w-full grid items-center m-auto">
                <div className="flex items-center flex-col content-center m-auto px-5">
                  <ContentHeader className={"text-xl m-auto flex flex-col"}>
                    There are no results for:{" "}
                    <span className="italic text-gray-300 underline">
                      {searchQuery?.search}
                    </span>
                  </ContentHeader>
                </div>
              </div>
            ) : (
              <div className="h-full w-full grid items-center m-auto">
                {searchMsg !== "" ? (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ delay: 0.5 }}
                    className="font-bold text-black w-[80%] lg:w-auto fixed top-20 left-1/2 -translate-x-1/2"
                  >
                    <Alert
                      onClose={(e) => {
                        setSearchMsg("");
                      }}
                      color="warning"
                      severity={"warning"}
                    >
                      {searchMsg}
                    </Alert>
                  </motion.div>
                ) : null}
                <div className="flex items-center flex-col content-center m-auto px-5">
                  <motion.span
                    initial={{ opacity: 0, y: "100%" }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: "100%" }}
                    transition={{ delay: 1.4 }}
                    className="bg-white p-5 text-black drop-shadow-lg rounded-full"
                  >
                    <SearchIcon color="warning" fontSize="large" />
                  </motion.span>
                  <motion.div
                    initial={{ opacity: 0, y: "100%" }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: "100%" }}
                    transition={{ delay: 1.5 }}
                  >
                    <ContentHeader className={"text-xl m-auto"}>
                      Your Search Results Will Appear Here
                    </ContentHeader>
                  </motion.div>
                </div>
              </div>
            )}
          </>
        )}

        <AnimatePresence initial={{ popacity: 0 }}>
          {searchDataMap?.length > 4 ? ContentFade : null}
        </AnimatePresence>
      </div>
    </motion.div>,
    document.getElementById("modal")
  );
}

const Styles = {
  searchIcon: {
    position: "fixed",
    top: "0",
    right: "0",
    padding: "20px",
    backgroundColor: "#fff",
    fontSize: "1.7em",
    color: "#ed1c24",
    borderLeft: "4px solid #ed1c24",
    cursor: "pointer",
    transition: ".5s ease all",
    boxShadow: "0px 0 20px -1px rgba(0, 0, 0, 0.58)",
    zIndex: "9992",
  },
  searchResultsHeading: {
    textAlign: "center",
    color: "#fff",
  },
  searchResultsContent: {
    gridTemplateColumns: "1fr 3fr",
  },
  searchResultsImage: {
    width: "100px",
    height: "100px",
    objectFit: "cover",
    boxSizing: "inherit",
    backgroundColor: "#630a0d",
    border: "2px solid #fff",
    borderRadius: "59px",
    margin: "auto",
  },
  searchResultsFade: {
    height: "10%",
    width: "40%",
    position: "fixed",
    bottom: "0",
    right: "0",
    background:
      "-webkit-gradient(linear, 0% 0%, 0% 101%, color-stop(-0.2, transparent), from(transparent), color-stop(0.6, rgba(237, 28, 36, 0.92)), to(rgb(237, 28, 36)))",
    pointerEvents: "none",
    transform: "translateY(100%)",
    zIndex: "99999",
  },
};

export default React.forwardRef(SearchResults);
