import React from "react";
import BadGateway from "../modules/Errors/BadGateway";
import { useLocation } from "react-router-dom";
import Form from "../modules/Form";
import ContentHeader from "../modules/ContentHeader";
import useApiPages from "../Utilities/useApiPages";
import { AnimatePresence, motion } from "framer-motion";

function Parsonsvlei({ AnimeCSS }) {
  const { state } = useLocation();

  const pages = useApiPages(state?.prevPath);

  const { pathname } = useLocation();

  if (!pages?.isLoaded) {
    return (
      <div className="loader">
        <span />
        <span />
        <span />
      </div>
    );
  } else if (pages?.isErr === true) {
    return <BadGateway />;
  } else {
    return (
      <div className={AnimeCSS()} style={{ overflow: "hidden" }}>
        <section className="banner">
          <div className="gd"></div>
          <AnimatePresence initial={{ opacity: 0 }}>
            {pages?.isLoadedImages ? null : (
              <motion.img
                initial={{ opacity: 0, scale: 1.5 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 1.5 }}
                src={pages?.images?.source_url}
                className="w-full h-full object-cover"
                alt=""
              />
            )}
          </AnimatePresence>

          <figure className="banner-text-reg w-full left">
            <div className=".banner-price-text-reg">
              <h1>{pages?.isPagesApi.title.rendered}</h1>
            </div>
          </figure>
        </section>

        <section className="bg-3">
          <div
            id={pathname === "/parsonsvlei" ? "post-192" : ""}
            className=" grid lg:grid-cols-2"
          >
            <div className="live-form-2 mt-[-120px] py-[40px] px-2 lg:p-[40px] w-full box-border z-10">
              <ContentHeader className={"text-left p-[20px] text-[3em] m-0"}>
                <em>PROPERTY</em> <i>ENQUIRY</i>
              </ContentHeader>
              <div className="widget_text ">
                <div className="textwidget custom-html-widget">
                  <Form />
                </div>
              </div>
            </div>
            <section
              className="about-content-text"
              style={{ width: "100%", margin: "0" }}
              dangerouslySetInnerHTML={{
                __html: pages?.isPagesApi.content.rendered,
              }}
            />
          </div>
        </section>
      </div>
    );
  }
}

export default Parsonsvlei;
