import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function ScrollToTop({ setisNavShowing, seticonChange }) {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => {
      setisNavShowing(false);
      seticonChange(false);
    }, 800);
  }, [pathname, seticonChange, setisNavShowing]);
  return null;
}

export default ScrollToTop;
