import React, { useContext, useEffect, useState } from "react";
import RecentPost from "../AppComponents/RecentPost/RecentPost";
import axios from "axios";
import BadGateway from "../modules/Errors/BadGateway";
import BannerInfoAlt from "../modules/BannerInfoAlt";
import ContentHeader from "../modules/ContentHeader";
import Button from "../modules/ui/button/button";
import CreateApiUrl from "../Context/ApiUrlCTX";
import useApiPages from "../Utilities/useApiPages";
import { useLocation } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";

function ServicesProducts({
  AnimeCSS,
  formModalHandleClick,
  formModalRemoveHandleClick,
  isFormModalon,
}) {
  const [HousingProducts, setHousingProducts] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isErr, setIsErr] = useState(false);

  const { APIURL } = useContext(CreateApiUrl);
  const HousingProductsApi = `${APIURL}/?rest_route=/wp/v2/housing_products&_embed`;

  useEffect(() => {
    const fetchData = async () => {
      await axios
        .get(HousingProductsApi)
        .then((res) => {
          setHousingProducts(res.data);
          setIsLoaded(true);
        })
        .catch((err) => {
          setIsErr(true);
          setIsLoaded(true);
          console.log(err);
        });
    };
    fetchData();
  }, [HousingProductsApi]);

  const { state } = useLocation();

  const pages = useApiPages(state?.prevPath);

  const HousingProductsFlisp = HousingProducts.map((HousingProducts) => {
    if (HousingProducts.featured_media === 0) return null;
    return (
      <li
        key={HousingProducts.id}
        className="last-of-type:col-span-2 first-of-type:col-span-2 p-0 cursor-pointer"
      >
        <div className="faq-ov-info-recents items-center relative h-[260px]">
          <img
            src={HousingProducts._embedded["wp:featuredmedia"][0].source_url}
            className="w-full h-full object-cover absolute"
            alt=""
          />
          <figure className="group anime-1 grid content-center items-center fill w-full h-full z-10 relative">
            <div className="w-full h-full absolute top-0 bottom-0 bg-black bg-opacity-10 group-hover:hover:bg-opacity-80" />
            <div className="text-white z-10 relative">
              <div
                className=""
                dangerouslySetInnerHTML={{
                  __html: HousingProducts.content.rendered,
                }}
              />
            </div>
          </figure>
        </div>
      </li>
    );
  });

  if (!isLoaded) {
    return (
      <div className="loader">
        <span />
        <span />
        <span />
      </div>
    );
  } else if (isErr === true) {
    return <BadGateway />;
  } else {
    return (
      <div className={AnimeCSS()} style={{ overflow: "hidden" }}>
        <section className="banner">
          <div className="gd"></div>
          <AnimatePresence initial={{ opacity: 0 }}>
            {pages?.isLoadedImages ? null : (
              <motion.img
                initial={{ opacity: 0, scale: 1.5 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 1.5 }}
                src={pages?.images?.source_url}
                className="w-full h-full object-cover"
                alt=""
              />
            )}
          </AnimatePresence>
          <BannerInfoAlt
            formModalHandleClick={formModalHandleClick}
            formModalRemoveHandleClick={formModalRemoveHandleClick}
            isFormModalon={isFormModalon}
          >
            <ContentHeader className={"text-white"}>
              <i>FIND</i> YOUR HOME <b>WITH QHAMA</b>
            </ContentHeader>
            <h4>FROM</h4>
            <p>
              R 1200<em>P/M</em>
            </p>
            <Button
              onClick={formModalHandleClick}
              buttonStyle="btn--gradient p-5 w-full"
              type="button"
            >
              Sign Up
            </Button>
          </BannerInfoAlt>
        </section>

        <section className="bg-3 ct">
          <div
            className="side-featured-images post-174 page type-page status-publish has-post-thumbnail hentry"
            style={{ display: "block" }}
          >
            <section
              className="about-content-text fill-width"
              dangerouslySetInnerHTML={{
                __html: pages?.isPagesApi?.content?.rendered,
              }}
            />
          </div>
        </section>

        <section className="text-center bg-3">
          <div className="w-[90%] m-auto">
            <div className="bg-ol" />
            <ContentHeader className={"text-2xl text-[#ed1c24]"}>
              HOUSING <em>PRODUCTS</em>
            </ContentHeader>
            <p className="font-bold text-justify">
              As an accredited social housing institution, Qhama is involved in
              the development of following housing instruments
            </p>
            <ul className="flex mt-5 flex-col lg:grid lg:grid-cols-3 m-auto gap-5 pb-5">
              {HousingProductsFlisp}
              <div className="clearfix" />
            </ul>
          </div>
        </section>
        <RecentPost />
      </div>
    );
  }
}

export default ServicesProducts;
