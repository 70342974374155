import React, { useContext, useEffect, useState } from "react";
import RoomsSlider from "../modules/DataSlider/RoomsSlider";
import RecentPost from "../AppComponents/RecentPost/RecentPost";
import ContentHeader from "../modules/ContentHeader";
import BannerInfoAlt from "../modules/BannerInfoAlt";
import Button from "../modules/ui/button/button";
import Axios from "axios";
import MainSlider from "../modules/DataSlider/MainSlider";
import CreateApiUrl from "../Context/ApiUrlCTX";

function Rooms({
  AnimeCSS,
  formModalHandleClick,
  formModalRemoveHandleClick,
  isFormModalon,
  openVideo,
}) {
  const formatPrice = (price) => {
    const newPriceFormat = new Intl.NumberFormat().format(price);
    return newPriceFormat;
  };
  const [ctaHeader, setCtaHeader] = useState([]);
  const { APIURL } = useContext(CreateApiUrl);
  const ctaHeaderApi = `${APIURL}/?rest_route=/wp/v2/cta&_embed`;

  useEffect(() => {
    Axios.get(ctaHeaderApi)
      .then((res) => {
        setCtaHeader(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [ctaHeaderApi]);
  return (
    <div className={AnimeCSS()} style={{ overflow: "hidden" }}>
      <section className="banner">
        <div className="gd" />
        <MainSlider />
        {ctaHeader.map((cat) => {
          return (
            <BannerInfoAlt
              isFormModalon={isFormModalon}
              formModalHandleClick={formModalHandleClick}
              formModalRemoveHandleClick={formModalRemoveHandleClick}
            >
              <div dangerouslySetInnerHTML={{ __html: cat.content.rendered }} />
              <h4>FROM</h4>
              <p>
                R{formatPrice(cat.acf.unit_prices)}
                <em>P/M</em>
              </p>
              <Button
                onClick={formModalHandleClick}
                buttonStyle="btn--gradient p-5 w-full"
                type="button"
              >
                Sign Up
              </Button>
            </BannerInfoAlt>
          );
        })}

        <div className="play_btn">
          <Button
            onClick={() => openVideo()}
            buttonStyle="btn--gradient--play"
            type="button"
          >
            play video
          </Button>
        </div>
      </section>

      <section className="content-rooms">
        <div className="wrapper-content-rooms">
          <ContentHeader className={"text-xl"}>
            <em>EXPLORE</em> <i>WHAT WE OFFER</i>
          </ContentHeader>
          <RoomsSlider />
        </div>
      </section>

      <RecentPost />
    </div>
  );
}

export default Rooms;
