import Axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CreateApiUrl from "../../Context/ApiUrlCTX";
import ContentHeader from "../../modules/ContentHeader";

function RecentPost() {
  const { APIURL } = useContext(CreateApiUrl);
  const ctaHeaderApi = `${APIURL}/?rest_route=/wp/v2/_rooms&_embed`;

  const formatPrice = (price) => {
    const newPriceFormat = new Intl.NumberFormat().format(price);
    return newPriceFormat;
  };
  const [ctaHeader, setCtaHeader] = useState([]);

  useEffect(() => {
    Axios.get(ctaHeaderApi)
      .then((res) => {
        setCtaHeader(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [ctaHeaderApi]);

  const PropertiesCardDetails = ctaHeader.map((item, i) => {
    if (item.featured_media === 0) return null;
    return (
      <Link
        key={i}
        to={{
          pathname: `/Rooms/${item.slug}`,
          state: { prevPath: item.id },
        }}
      >
        <li className="w-full">
          <div
            className="content-room-info-recents group h-[260px] relative overflow-hidden drop-shadow-lg border-l-[6px] border-[#c00f0e] box-border"
            style={{ height: "260px", display: "flex" }}
          >
            <img
              src={item?._embedded["wp:featuredmedia"][0]?.source_url}
              className="w-full h-full object-cover"
              alt={item.title.rendered}
              style={{ objectFit: "cover" }}
            />
            <figure className="anime-1 fill m-0 absolute left-0 h-full w-full bg-black bg-opacity-50 group-hover:bg-opacity-10 box-border">
              <div className="precinct-text-align-recents absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-max">
                <ContentHeader
                  className={"text-white text-lg flex items-center"}
                >
                  {item.title.rendered}
                  {item.acf.apartment_view === "" ? null : (
                    <em className="p-[10px] rounded-full align-super text-xs ml-2 bg-gradient-to-r from-[#ed1c24] to-[#c30b49] text-white">
                      {item.acf.apartment_view}
                    </em>
                  )}
                </ContentHeader>
                <h4 className="text-white text-left align-super mb-[-18px]">
                  FROM
                </h4>{" "}
                <h3 className="m-0 text-center uppercase text-[3.5em] font-bold font-sans text-white w-fit">
                  R {formatPrice(item.acf.unit_price)}
                  <em className="p-[10px] rounded-full text-[16px] ml-2 align-super bg-gradient-to-r from-[#ed1c24] to-[#c30b49] text-white">
                    p/m
                  </em>
                </h3>
              </div>
            </figure>
          </div>
        </li>
      </Link>
    );
  });

  return (
    <section className="recent-posts bg-3 overflow-visible relative clear-both border-t-[5px] border-[#c00f0e] h-auto">
      <div className="bg-ol" />
      <ContentHeader className={"pb-0 text-[#c00f0e] text-2xl"}>
        Explore <em>our property</em>
      </ContentHeader>
      <ul className="lg:flex grid justify-evenly pb-4">
        {PropertiesCardDetails.slice(0, -2)}
      </ul>
    </section>
  );
}

export default RecentPost;
