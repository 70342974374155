import React from "react";
// import { StrictMode } from "react";
import { hydrateRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import ApiUrlCTXProvider from "./Context/ApiUrlCTXProvider";
import { BrowserRouter } from "react-router-dom";

const rootElement = document.getElementById("root");
const root = hydrateRoot(rootElement);

root.render(
  // <StrictMode>
  // </StrictMode>
  <BrowserRouter>
    <ApiUrlCTXProvider>
      <App />
    </ApiUrlCTXProvider>
  </BrowserRouter>
);

serviceWorker.unregister();

// import React from "react";
// import ReactDOM from "react-dom";
// import "./index.css";
// import App from "./App";
// import * as serviceWorker from "./serviceWorker";

// ReactDOM.hydrate(<App />, document.getElementById("root"));

// // If you want your app to work offline and load faster, you can change
// // unregister() to register() below. Note this comes with some pitfalls.
// // Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
