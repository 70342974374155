import React, { useEffect, useRef, useState, useContext } from "react";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { AnimatePresence, motion } from "framer-motion";
import SearchResults from "../../modules/QuickLinksData/SearchResults";
import Axios from "axios";
import ApiUrlCTX from "../../Context/ApiUrlCTX";

function Search(props) {
  function closeAllSearch() {
    props.setisSearchon((prev) => !prev);
    setsearchData([]);
    setsearchQuery({ search: "" });
    setSearchMsg("");
    setsearchItemsReady(true);
    setnoResults(false);
    setsearchRan(false);
  }
  const { formWidth } = props;
  const [searchQuery, setsearchQuery] = useState({ search: "" });

  const { APIURL } = useContext(ApiUrlCTX);

  const [searchData, setsearchData] = useState([]);

  const [searchMsg, setSearchMsg] = useState("");
  const [isLoading, setisLoading] = useState(false);

  const [searchItemsReady, setsearchItemsReady] = useState(false);
  const [noResults, setnoResults] = useState(false);
  const [searchRan, setsearchRan] = useState(false);

  const [searchIconWidth, setsearchIconWidth] = useState(0);

  const roomsApi = `${APIURL}/?rest_route=/wp/v2/_rooms&_embed`;

  const ContentFade = (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className={`h-20 w-full sticky list-item lg:fixed bottom-0 right-0 z-[99999] pointer-events-none bg-gradient-to-t from-[#ed1c24] to-transparent`}
      // style={Styles.searchResultsFade}
    />
  );

  const submitSearch = (e) => {
    e.preventDefault();

    if (searchQuery?.search === undefined || searchQuery?.search === "") {
      return setSearchMsg("You can't submit a blank form");
    } else {
      Axios.get(roomsApi)
        .then(setisLoading(true))
        .then((res) => {
          const data = res.data;
          const selectedData = data.map((items) => {
            const { title, content, acf, _embedded, slug, featured_media, id } =
              items;
            const { unit_price, apartment_view } = acf;
            return {
              title,
              content,
              unit_price,
              _embedded,
              slug,
              featured_media,
              apartment_view,
              id,
            };
          });
          setsearchData(selectedData);
          setisLoading(false);
          setsearchRan(true);
        })
        .catch((err) => console.log(err));
    }
  };

  const searchIconWidthRef = useRef();
  const searchAreaRef = useRef();
  const formRefCheck = useRef();

  useEffect(() => {
    const searchIconWidth = searchIconWidthRef?.current?.clientWidth;
    setsearchIconWidth(searchIconWidth);
  }, []);

  const w = formWidth - searchIconWidth;

  // alert(formWidth === 424 ? "100%" : "40%");
  // console.log(formWidth !== 414 ? "40%" : w || formWidth !== 424 ? "100%" : w);

  return (
    <div>
      {!props.isSearchon && (
        <span
          ref={searchIconWidthRef}
          onClick={
            props.isSearchon
              ? props.RemoveHandleClickSearchon
              : props.HandleClickSearchon
          }
          // style={Styles.searchIcon}
          className="fixed hover:bg-[#ed1c24] hover:text-white flex top-0 right-0 p-5 text-xl bg-white border-l-4 h-16 border-[#ed1c24] text-[#ed1c24] cursor-pointer drop-shadow-lg z-[999999]"
        >
          <SearchIcon fontSize="inherit" color="inherit" />
        </span>
      )}
      <form
        ref={formRefCheck}
        onSubmit={submitSearch}
        role="search"
        className={classNames.Form}
      >
        <AnimatePresence initial={{ x: "100%" }}>
          {props.isSearchon && (
            <>
              <motion.label
                initial={{ x: "100%" }}
                animate={{ x: 0 - searchIconWidth }}
                exit={{ x: "100%" }}
                transition={{ type: "spring", damping: 10 }}
                className={`block fixed right-0 z-50 h-16 w-full lg:w-2/5 top-0 border-l-4 border-[#ed1c24] text-[#ed1c24]`}
                style={{
                  width: formWidth > 424 ? "40%" : w,
                }}
              >
                <input
                  onChange={(e) => {
                    setsearchQuery({
                      ...searchQuery,
                      [e.target.name]: e.target.value,
                    });
                  }}
                  type="search"
                  className={`float-left relative p-6 top-0 text-base border-0  w-full h-full drop-shadow-lg z-0 transition-transform delay-300`}
                  placeholder="What are you looking for?"
                  name="search"
                  value={searchQuery?.search}
                />
              </motion.label>
              <button
                onClick={() => {}}
                className="fixed lg:hover:bg-[#ed1c24] lg:hover:text-white flex top-0 right-0 p-5 text-xl lg:bg-white bg-[#ed1c24] border-l-4 h-16 border-[#ed1c24] text-white lg:text-[#ed1c24] cursor-pointer drop-shadow-lg z-[999999]"
                type="submit"
                hidden
                defaultValue="Search"
              >
                <SearchIcon fontSize="inherit" color="inherit" />
              </button>
            </>
          )}
        </AnimatePresence>
      </form>

      <AnimatePresence initial={{ y: "-100%", opacity: 0 }}>
        {props.isSearchon === true ? (
          <SearchResults
            ref={searchAreaRef}
            ContentFade={ContentFade}
            isSearchon={props.isSearchon}
            searchResultsHeading={Styles.searchResultsHeading}
            searchQuery={searchQuery}
            searchData={searchData}
            setsearchData={setsearchData}
            searchMsg={searchMsg}
            setSearchMsg={setSearchMsg}
            isLoading={isLoading}
            setsearchItemsReady={setsearchItemsReady}
            setnoResults={setnoResults}
            searchItemsReady={searchItemsReady}
            noResults={noResults}
            searchRan={searchRan}
            formWidth={formWidth}
            width={w}
            formRefCheck={formRefCheck}
            searchIconWidth={searchIconWidth}
            closeAllSearch={closeAllSearch}
          />
        ) : null}
      </AnimatePresence>

      <AnimatePresence initial={{ opacity: 0 }}>
        {props.isSearchon && (
          <motion.div
            onClick={() => {
              closeAllSearch();
            }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className={`fixed h-full w-full left-0 bg-[#ed1c24] backdrop-blur-sm bg-opacity-40 top-0 bottom-0 right-0 cursor-pointer z-10 block`}
          />
        )}
        fsdfsdfsdfs
      </AnimatePresence>

      <AnimatePresence
        initial={{ y: "-100%", opacity: 0 }}
        transition={{ delay: 0.2 }}
      >
        {props.isSearchon ? (
          <motion.button
            onClick={closeAllSearch}
            initial={{ opacity: 0, y: "100%" }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: "100%" }}
            transition={{ delay: 1 }}
            className="fixed bottom-5 right-5 flex text-[#ed1c24] bg-white p-2 z-50 drop-shadow-3xl rounded-full"
          >
            <CloseIcon /> Close
          </motion.button>
        ) : null}
      </AnimatePresence>
    </div>
  );
}

const classNames = {
  Form: "search-form",
  Label: "search-icon",
  Span: "screen-reader-text icon icon-search search-btn",
  Input: "search-submit",
};

const Styles = {
  searchIcon: {
    position: "fixed",
    top: "0",
    right: "0",
    padding: "20px",
    backgroundColor: "#fff",
    fontSize: "1.7em",
    color: "#ed1c24",
    borderLeft: "4px solid #ed1c24",
    cursor: "pointer",
    transition: ".5s ease all",
    boxShadow: "0px 0 20px -1px rgba(0, 0, 0, 0.58)",
    zIndex: "9992",
  },
  searchResultsHeading: {
    textAlign: "center",
    color: "#fff",
  },
  searchResultsContent: {
    color: "#fff",
    display: "grid",
    gridTemplateColumns: "1fr 3fr",
    padding: "10px 10px",
    boxSizing: "border-box",
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    alignItems: "center",
  },
  searchResultsImage: {
    width: "100px",
    height: "100px",
    objectFit: "cover",
    boxSizing: "inherit",
    backgroundColor: "#630a0d",
    border: "2px solid #fff",
    borderRadius: "59px",
    margin: "auto",
  },
  searchResultsFade: {
    height: "10%",
    width: "40%",
    position: "fixed",
    bottom: "0",
    right: "0",
    background:
      "-webkit-gradient(linear, 0% 0%, 0% 101%, color-stop(-0.2, transparent), from(transparent), color-stop(0.6, rgba(237, 28, 36, 0.92)), to(rgb(237, 28, 36)))",
    pointerEvents: "none",
    transform: "translateY(100%)",
    zIndex: "99999",
  },
};

export default Search;
