import React from "react";
import InputDF from "./ui/input/inputDF";
import { useState } from "react";
import Select from "./ui/input/Select";
import FormButton from "./ui/input/FormButton";

function FormUnit() {
  const formSchema = {
    name: "",
    email: "",
    number: "",
    selectedFacility: "",
    selectedRoom: "",
    selectedValue: "",
  };

  const [formFields, setFormFields] = useState(formSchema);
  const [isLoading, setIsLoading] = useState(false);
  const [formErr, setFormErr] = useState(false);
  const [formNotifications, setFormNotiofications] = useState({
    subscribed: "",
    pending: "",
    memberExist: "",
    Failed: "",
  });

  function handleSubmit(e) {
    e.preventDefault();
    setFormFields(formSchema);

    if (formFields) {
      fetch(
        `https://qhama-server.herokuapp.com/unitMembersAdd?email=${formFields.email}&name=${formFields.name}&number=${formFields.number}&selectedFacility=${formFields.selectedFacility}&selectedRoom=${formFields.selectedRoom}&selectedValue=${formFields.selectedValue}`
      )
        .then((res) => res.json())
        .then(setIsLoading(true))
        .then((json) => {
          if (json.status === "subscribed") {
            setIsLoading(false);
            setFormNotiofications({
              subscribed: "subscribed",
            });
          } else if (json.title === "Member Exists") {
            setIsLoading(false);
            setFormNotiofications({
              memberExist: "Member Exists",
            });
          } else if (json.status === "pending") {
            setIsLoading(false);
          } else {
            setFormErr(true);
            setIsLoading(false);
          }
        })
        .catch((err) => console.log(err));
    }
    setFormFields(formSchema);
  }

  function notificationTimeoutStyles() {
    if (formNotifications.subscribed === "subscribed") {
      return "subscribed";
    } else if (formNotifications.memberExist === "Member Exists") {
      return "memberExists";
    } else if (formNotifications.pending === "pending") {
      return "pending";
    } else if (formErr === true) {
      return "failed";
    }
    return "";
  }

  return (
    <div className="widget_text ">
      <div className="textwidget custom-html-widget">
        <form onSubmit={handleSubmit}>
          <InputDF
            placeholder={"Your Name & Surname"}
            name={"name"}
            id={"Your-Name-&-Surname"}
            className={"input--defualt"}
            type={"text"}
            onChange={(e) => {
              setFormFields({ ...formFields, [e.target.name]: e.target.value });
            }}
            value={formFields.name}
          />
          <InputDF
            placeholder={"Contact Number"}
            name={"number"}
            id={"Cell-Number"}
            className={"input--defualt"}
            type={"tel"}
            onChange={(e) => {
              setFormFields({ ...formFields, [e.target.name]: e.target.value });
            }}
            value={formFields.number}
          />
          <InputDF
            placeholder={"Email Address"}
            name={"email"}
            id={"Email"}
            className={"input--defualt"}
            type={"email"}
            onChange={(e) => {
              setFormFields({ ...formFields, [e.target.name]: e.target.value });
            }}
            value={formFields.email}
          />
          <Select
            name={"selectedFacility"}
            id={"Selected-Facility"}
            className={"input--defualt"}
            onChange={(e) => {
              setFormFields({
                ...formFields,
                [e.target.name]: e.target.value,
              });
            }}
            value={formFields.selectedFacility}
          >
            <option value={""}>{"Select a Facility"}</option>
            <option value={"Steve Biko Precinct"}>
              {"Steve Biko Precinct"}
            </option>
            <option value={"Pasinsvlie Precinct"}>
              {"Pasinsvlie Precinct"}
            </option>
          </Select>
          <Select
            name={"SelectedRoom"}
            id={"Selected-Room"}
            className={"input--defualt"}
            required={"required"}
            onChange={(e) => {
              setFormFields({ ...formFields, [e.target.name]: e.target.value });
            }}
            value={formFields.selectedRoom}
          >
            <option value={""}>{"Select Bedrooms"}</option>
            <option value={"Bachelor"}>{"Bachelor"}</option>
            <option value={"One Bedroom"}>{"One Bedroom"}</option>
            <option value={"Two Bedroom"}>{"Two Bedroom"}</option>
          </Select>
          <Select
            name={"SelectedValue"}
            id={"Selected-Value"}
            className={"input--defualt"}
            required={"required"}
            onChange={(e) => {
              setFormFields({ ...formFields, [e.target.name]: e.target.value });
            }}
            value={formFields.selectedValue}
          >
            <option value={""}>{"Rent or Buy"}</option>
            <option value={"Rent"}>{"Rent"}</option>
            <option value={"Buy"}>{"Buy"}</option>
          </Select>
          <FormButton
            value={isLoading ? preLoader() : "Sign up Now"}
            buttonStyle={notificationTimeoutStyles()}
            type={"submit"}
            className={`${notificationTimeoutStyles()} btn--gradient`}
            style={{ marginTop: "20px", padding: "20px", fontSize: "14px" }}
          />
        </form>
      </div>
    </div>
  );
}

function preLoader() {
  return (
    <div className="loader2" style={{ background: "transparent" }}>
      <span />
      <span />
      <span />
    </div>
  );
}

export default FormUnit;
