import React, { useContext, useEffect, useRef, useState } from "react";
import AmenitiesSlider from "../modules/DataSlider/AmenitiesSlider";
import AmenitiesBannerSlider from "../modules/DataSlider/AmenitiesBannerSlider";
import RecentPost from "../AppComponents/RecentPost/RecentPost";
import BannerInfoAlt from "../modules/BannerInfoAlt";
import ContentHeader from "../modules/ContentHeader";
import Button from "../modules/ui/button/button";
import Axios from "axios";
import CreateApiUrl from "../Context/ApiUrlCTX";

function Amenities({
  AnimeCSS,
  formModalHandleClick,
  formModalRemoveHandleClick,
  isFormModalon,
}) {
  const formatPrice = (price) => {
    const newPriceFormat = new Intl.NumberFormat().format(price);
    return newPriceFormat;
  };

  const { APIURL } = useContext(CreateApiUrl);
  const ctaHeaderApi = `${APIURL}/?rest_route=/wp/v2/cta&_embed`;

  const [ctaHeader, setCtaHeader] = useState([]);

  useEffect(() => {
    Axios.get(ctaHeaderApi)
      .then((res) => {
        setCtaHeader(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [ctaHeaderApi]);

  const bannerRef = useRef();
  const bannerRefWidth = bannerRef?.current?.clientWidth;
  const [deviceWidth, setdeviceWidth] = useState(0);

  useEffect(() => {
    setdeviceWidth(bannerRefWidth);
  }, [bannerRefWidth]);

  return (
    <div ref={bannerRef} className={AnimeCSS()} style={{ overflow: "hidden" }}>
      <section className="banner">
        <div className="gd" />
        <AmenitiesBannerSlider deviceWidth={deviceWidth} />
        {ctaHeader.map((cat) => {
          return (
            <BannerInfoAlt
              isFormModalon={isFormModalon}
              formModalHandleClick={formModalHandleClick}
              formModalRemoveHandleClick={formModalRemoveHandleClick}
            >
              <div dangerouslySetInnerHTML={{ __html: cat.content.rendered }} />
              <h4>FROM</h4>
              <p>
                R{formatPrice(cat.acf.unit_prices)}
                <em>P/M</em>
              </p>
              <Button
                onClick={formModalHandleClick}
                buttonStyle="btn--gradient p-5 w-full"
                type="button"
              >
                Sign Up
              </Button>
            </BannerInfoAlt>
          );
        })}
      </section>

      <section className="content-rooms">
        <div className="wrapper-content-rooms">
          <ContentHeader className={"text-2xl"}>
            <em>EXPLORE</em> <i>WHAT WE OFFER</i>
          </ContentHeader>
          <AmenitiesSlider />
        </div>
      </section>

      <RecentPost />
    </div>
  );
}

export default Amenities;
