// import React from "react";
import CreateApiUrlContext from "./ApiUrlCTX";

export default function ApiUrlCTXProvider(props) {
  const APIURL = "https://qhama.co.za/wp";
  // const OnlineURL = "https://qhama.co.za/wp";
  return (
    <CreateApiUrlContext.Provider value={{ APIURL }}>
      {props.children}
    </CreateApiUrlContext.Provider>
  );
}
