import React from "react";
import ReactDOM from "react-dom";
import CloseIcon from "@mui/icons-material/Close";

function VideoModal({ openVideo }) {
  return ReactDOM.createPortal(
    <div
      className={
        "animate__animated animate__backInUp fixed w-screen h-screen z-[9999999]"
      }
      style={{
        backgroundSize: "cover",
      }}
    >
      <div
        className={
          "fixed w-full h-full bg-gradient-to-r from-[#960a10] to-[#c30b49] backdrop-blur-sm bg-opacity-40 overflow-hidden"
        }
        style={{
          opacity: 0.8,
        }}
      />
      <div className={"content-wrapper-signup-modal"}>
        <div className="modalClose">
          <CloseIcon onClick={openVideo} />
        </div>
        <div className={"grid grid-cols-1 h-auto aspect-video"}>
          <iframe
            width="100%"
            height="100%"
            src="https://www.youtube-nocookie.com/embed/5e0xGSG3Q74?controls=0"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
            className=" w-full h-full object-cover"
          ></iframe>
        </div>
      </div>
    </div>,
    document.getElementById("modal")
  );
}

export default VideoModal;
