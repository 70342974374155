import Axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import RecentPost from "../AppComponents/RecentPost/RecentPost";
import CreateApiUrlContext from "../Context/ApiUrlCTX";

function AboutSteveBiko({ AnimeCSS }) {
  const [AboutSteveBiko, setAboutSteveBiko] = useState([]);
  const { APIURL } = useContext(CreateApiUrlContext);
  const ImagesApi = `${APIURL}/wp-json/wp/v2/pages?per_page=15&_embed`;

  useEffect(() => {
    const fetchData = async () => {
      await Axios.get(ImagesApi)
        .then((res) => {
          setAboutSteveBiko(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    fetchData();
  }, [ImagesApi]);

  const checkImage = () => {
    if (AboutSteveBiko[13]?.featured_media === 0) return;
    return AboutSteveBiko[13]?._embedded["wp:featuredmedia"][0]?.source_url;
  };

  return (
    <div className={AnimeCSS()} style={{ overflow: "hidden" }}>
      <section className="banner">
        <div className="gd"></div>
        <img src={checkImage()} className="h-full w-full object-cover" alt="" />
        <figure className="banner-text-reg left">
          <div className="banner-price-text-reg">
            <h1>
              ABOUT <em>STEVE BIKO Precinct</em>
            </h1>
          </div>
        </figure>
      </section>

      <section className="bg-3">
        <div id="" class="grid lg:grid-cols-2">
          <ul
            style={{ padding: "0", float: "left" }}
            className="hidden lg:flex"
          >
            <li
              style={{ width: "100%" }}
              className="left-0 top-0 bottom-0 p-0 overflow-hidden h-full"
            >
              <img
                alt=""
                src={`${APIURL}/wp-content/uploads/2022/06/3.jpg`}
                className="w-full h-full object-cover"
              />
            </li>
          </ul>
          <section
            className="about-content-texts bg-white drop-shadow-lg p-4 lg:p-[40px] border-l-[4px] border-[#cb1c1e]"
            style={{ width: "100%", margin: "0" }}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: AboutSteveBiko[13]?.content.rendered,
              }}
            />
          </section>
        </div>
      </section>
      <RecentPost />
    </div>
  );
}

export default AboutSteveBiko;
