import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import BadGateway from "../Errors/BadGateway";
import { motion } from "framer-motion";
import { useLocation } from "react-router-dom";
import CreateApiUrl from "../../Context/ApiUrlCTX";

function MainSlider({ bannerRefWidth }) {
  const [images, setImages] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isErr, setIsErr] = useState(false);
  const [activeMainSlide, setactiveMainSlide] = useState(0);

  const { APIURL } = useContext(CreateApiUrl);
  const ImagesApi = `${APIURL}/?rest_route=/wp/v2/banner_slider&_embed`;

  useEffect(() => {
    axios
      .get(ImagesApi)
      .then((res) => {
        setImages(res.data);
        setIsLoaded(true);
      })
      .catch((err) => {
        setIsErr(true);
        setIsLoaded(true);
      });
  }, [ImagesApi]);

  const runSlider = () => {
    setTimeout(() => {
      if (images.length - 1 === activeMainSlide) {
        setactiveMainSlide(0);
      } else if (activeMainSlide >= 0) {
        setactiveMainSlide(activeMainSlide + 1);
      }
    }, 5000);
  };

  runSlider();

  const { pathname } = useLocation();

  const listImages = images.map((images, i) => {
    return (
      <motion.div
        key={images.id}
        initial={{ opcity: 0.5 }}
        animate={{
          opacity: 1,
          left: `${(i - activeMainSlide) * bannerRefWidth || 0}px`,
        }}
        exit={{ opacity: 0.5 }}
        transition={{ type: "spring", damping: 20, stiffness: 100 }}
        className={`${
          pathname === "/Rooms" ? "h-full" : "h-[320px]"
        } w-screen lg:h-full absolute`}
      >
        <img
          alt={""}
          src={images._embedded["wp:featuredmedia"][0].source_url}
          className="w-full h-full object-cover"
        />
        {pathname === "/Rooms" ? null : (
          <aside
            style={{ left: "unset" }}
            className="bg-black bg-opacity-60 absolute bottom-[8%] lg:bottom-[13%] p-2 lg:p-5 m-0 text-left font-bold text-[#ed1c24] translate-x-[5%] w-[80%] lg:w-2/4 border-l-4 border-[#ed1c24]"
          >
            <h1 className="text-left lg:text-3xl m-0 text-white font-sans uppercase">
              {images.title.rendered}
            </h1>
          </aside>
        )}
      </motion.div>
    );
  });

  if (!isLoaded) {
    return (
      <div style={{ height: "100%" }} className="loader">
        <span />
        <span />
        <span />
      </div>
    );
  } else if (isErr === true) {
    return <BadGateway />;
  } else {
    return listImages;
  }
}

export default MainSlider;
