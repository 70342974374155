import React from "react";
import ContentHeader from "./ContentHeader";
import { Link } from "react-router-dom";
import Button from "../modules/ui/button/button";

function Excerpt() {
  return (
    <section className="bg-white border-solid border-t-5 border-[#ed1c24]">
      <div className="bg-hero-pattern-light h-full opacity-30 w-full absolute bottom-0 z-0" />
      <div className="w-[90%] lg:w-[64%] mx-auto my-0 z-10 relative">
        <div className="textwidget">
          <ContentHeader className={"text-left py-4 text-[#cb1c1e] text-2xl"}>
            <i>QHAMA</i>
          </ContentHeader>
          <p className="text-justify font-sans text-xl m-0 pb-5">
            Qhama is a fully accredited and{" "}
            <Link to="#">female lead non-profit company</Link> established as a
            special purpose vehicle for social housing and related housing
            instruments. <Link to="#">The company is 100% black owned</Link>.
          </p>
          <p className="text-justify font-sans text-xl m-0 pb-5">
            Qhama was established in October 2013 and in February 2014 it was
            accredited by the{" "}
            <Link to="#">Social Housing Regulatory Authority (SHRA)</Link> as a
            Social Housing Institute. The company is based in Port Elizabeth and
            has satellite office in East London, Eastern Cape
          </p>
          <Button
            buttonStyle="bg-[#ed1c24] w-full lg:w-auto hover:bg-gray-600 rounded-full py-[10px] px-[50px] mb-4 font-bold uppercase border-0"
            type="button"
          >
            <Link
              to={{
                pathname: `/about-us/ourstory`,
                state: { prevPath: 56 },
              }}
              className="text-white"
            >
              Learn More
            </Link>
          </Button>
        </div>
      </div>
    </section>
  );
}

export default Excerpt;
