import React from "react";
import ContentHeader from "./ContentHeader";

function BannerInfo(props) {
  const content = (
    <figure
      className={`${props.className} absolute top-0 right-0 m-0 float-right mr-[3%] w-[450px] h-full bg-[#01010166]`}
    >
      <div className="banner-price text-size-home absolute w-full p-5 box-border text-white font-sans top-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <div className="">
          <div className="textwidget">{props.children}</div>
        </div>
      </div>
    </figure>
  );

  if (props.children !== undefined) {
    return content;
  } else {
    return <ContentHeader>Please reload the page</ContentHeader>;
  }
}

export default BannerInfo;
