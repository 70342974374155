import React from "react";

function BadGateway({ style }) {
  return (
    <img
      style={{ style }}
      className="fetchErr"
      alt=""
      src="/images/502-BG.png"
    />
  );
}

export default BadGateway;
