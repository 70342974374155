import React, { useState } from "react";

import { Link } from "react-router-dom";
import Button from "../../modules/ui/button/button";
import InputDF from "../../modules/ui/input/inputDF";

function Footer() {
  const formSchema = {
    email: "",
  };

  const [formFields, setFormFields] = useState(formSchema);
  const [isLoading, setIsLoading] = useState(false);
  const [formErr, setFormErr] = useState(false);
  const [formNotifications, setFormNotiofications] = useState({
    subscribed: "",
    pending: "",
    memberExist: "",
    Failed: "",
  });

  function handleSubmit(e) {
    e.preventDefault();
    setFormFields(formSchema);

    if (formFields) {
      fetch(
        `https://qhama-server.herokuapp.com/api/membersAdd?email=${formFields.email}`
      )
        .then((res) => res.json())
        .then(setIsLoading(true))
        .then((json) => {
          if (json.status === "subscribed") {
            setIsLoading(false);
            setFormNotiofications({
              subscribed: "subscribed",
            });
          } else if (json.title === "Member Exists") {
            setIsLoading(false);
            setFormNotiofications({
              memberExist: "Member Exists",
            });
          } else if (json.status === "pending") {
            setIsLoading(false);
          } else {
            setFormErr(true);
            setIsLoading(false);
          }
        })
        .catch((err) => console.log(err));
    }
  }

  function notificationTimeoutStyles() {
    if (formNotifications.subscribed === "subscribed") {
      return "subscribed";
    } else if (formNotifications.memberExist === "Member Exists") {
      return "memberExists";
    } else if (formNotifications.pending === "pending") {
      return "pending";
    } else if (formErr === true) {
      return "failed";
    }
    return "";
  }

  function preLoader() {
    return (
      <div className="loader2" style={{ background: "transparent" }}>
        <span />
        <span />
        <span />
      </div>
    );
  }

  return (
    <>
      <footer className="h-auto py-[10px] px-5 z-10 relative lg:sticky bottom-0 w-full bg-white lg:grid">
        <div
          className="bg-hero-pattern-footer left-0 right-0 h-full opacity-30 w-full absolute bottom-0 z-0"
          style={{ backgroundAttachment: "fixed" }}
        />
        <div className="footer-wrappers relative">
          <div>
            <ul
              id="menu-footer-menu"
              className="lg:float-left lg:pl-[2%] box-border grid gap-5 lg:gap-2 lg:flex"
            >
              <li
                id="menu-item-274"
                className="lg:mr-[10px] p-[10px] text-white rounded-full text-[14px] bg-gradient-to-r from-[#ed1c24] to-[#c30b49] hover:from-[#c30b49] hover:to-[#c30b49]"
              >
                <Link className="text-white" to="/faq-overview">
                  FAQ
                </Link>
              </li>
              <li
                id="menu-item-273"
                className=" p-[10px] text-white rounded-full text-[14px] bg-gradient-to-r from-[#ed1c24] to-[#c30b49] hover:from-[#c30b49] hover:to-[#c30b49]"
              >
                <Link className="text-white" to="/policies-and-report">
                  POLICIES AND REPORT
                </Link>
              </li>
              <li className=" p-[10px] text-white rounded-full text-[14px] bg-gradient-to-r from-[#ed1c24] to-[#c30b49] hover:from-[#c30b49] hover:to-[#c30b49]">
                © Qhama SHI. All Rights Reserved.
              </li>
              <li className="lg:ml-4 p-[10px] text-white rounded-full text-[14px] bg-gradient-to-r from-[#ed1c24] to-[#c30b49] hover:from-[#c30b49] hover:to-[#c30b49]">
                <Link className="text-white" to="/www.selectfew.co.za">
                  Developed by Select Few (Pty) Ltd
                </Link>
              </li>
            </ul>
          </div>
          <div className="float-none lg:float-right relative mr-0">
            <form
              onSubmit={handleSubmit}
              className="grid lg:flex gap-5 my-5 lg:my-0 lg:py-0"
            >
              <InputDF
                placeholder={"Qhama Newsletter"}
                type={"email"}
                name={"email"}
                id={"email"}
                required={""}
                onChange={(e) => {
                  setFormFields({ ...formFields, email: e.target.value });
                }}
                className="bg-white border-0 p-[10px] m-0 rounded-full w-full col-span-2"
                value={formFields.email}
              />
              <Button
                value={isLoading ? preLoader() : "Subscribe"}
                buttonStyle={`${notificationTimeoutStyles()} text-white col-span-2 my-0 bg-gradient-to-r from-[#ed1c24] to-[#c30b49] hover:from-[#c30b49] hover:to-[#c30b49] border-0 rounded-full py-[10px] px-5 my-0 mx-0 w-full font-bold uppercase`}
                type={"submit"}
                className=""
              />
            </form>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
