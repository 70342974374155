import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import { useLocation } from "react-router-dom";
import RecentPost from "../../AppComponents/RecentPost/RecentPost";
import useApiPages from "../../Utilities/useApiPages";

function NavPagesTemplate(props) {
  const { AnimeCSS } = props;

  const { state } = useLocation();

  function Loader({ style }) {
    return (
      <div
        className="loader"
        style={{ background: "transparent", height: "100%" }}
      >
        <span style={style} />
        <span style={style} />
        <span style={style} />
      </div>
    );
  }

  const pages = useApiPages(state?.prevPath);

  return (
    <div className={AnimeCSS()} style={{ overflow: "hidden" }}>
      <section className={"banner animate__animated animate__zoomIn"}>
        <div className={"gd"}></div>
        <AnimatePresence initial={{ opacity: 0 }}>
          {pages?.isLoadedImages ? null : (
            <motion.img
              initial={{ opacity: 0, scale: 1.5 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 1.5 }}
              src={pages?.images?.source_url}
              className="w-full h-full object-cover"
              alt=""
            />
          )}
        </AnimatePresence>

        <figure className={"banner-text-reg left"}>
          <div
            className={
              "banner-price-text-reg animate__animated animate__fadeInRightBig"
            }
          >
            {!pages?.isLoaded ? null : (
              <h1
                dangerouslySetInnerHTML={{
                  __html: pages?.isPagesApi?.title?.rendered,
                }}
              />
            )}
          </div>
        </figure>
      </section>

      <section className={"bg-3 ct"}>
        <div
          id={"post-86"}
          className={"side-featured-images"}
          style={{ display: "block" }}
        >
          {!pages?.isLoaded ? (
            <Loader style={{ background: "#cb1c1e" }} />
          ) : (
            <section
              className="about-content-text fill-width animate__animated animate__fadeInUpBig"
              dangerouslySetInnerHTML={{
                __html: pages?.isPagesApi?.content?.rendered,
              }}
            />
          )}
        </div>
      </section>

      <RecentPost />
    </div>
  );
}
export default NavPagesTemplate;
