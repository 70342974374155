import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import BadGateway from "../modules/Errors/BadGateway";
import Button from "../modules/ui/button/button";
import BannerInfoAlt from "../modules/BannerInfoAlt";
import Downloads from "../modules/QuickLinksData/Downloads";
import ContentHeader from "../modules/ContentHeader";
import CreateApiUrl from "../Context/ApiUrlCTX";

function ContacttUs({
  AnimeCSS,
  CloseModal,
  isModalon,
  OpenModal,
  formModalHandleClick,
  formModalRemoveHandleClick,
  isFormModalon,
  openVideo,
}) {
  const [images, setImages] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isErr, setIsErr] = useState(false);

  const { APIURL } = useContext(CreateApiUrl);
  const ctaHeaderApi = `${APIURL}/?rest_route=/wp/v2/cta&_embed`;
  const ImagesApi = `${APIURL}/wp-json/wp/?rest_route=/wp/v2/address&_embed`;

  const [ctaHeader, setCtaHeader] = useState([]);

  useEffect(() => {
    axios
      .get(ctaHeaderApi)
      .then((res) => {
        setCtaHeader(res.data);
      })
      .catch((err) => {});
  }, [ctaHeaderApi]);

  useEffect(() => {
    axios
      .get(ImagesApi)
      .then((res) => {
        setImages(res.data);
        setIsLoaded(true);
      })
      .catch((err) => {
        setIsErr(true);
        setIsLoaded(true);
      });
  }, [ImagesApi]);

  const listAmenities = images.map((images) => {
    if (images.featured_media === 0) {
      return null;
    } else {
      return (
        <div
          key={images.id}
          className="content-room-info contact"
          style={{ marginBottom: "40px" }}
        >
          <img
            src={images._embedded["wp:featuredmedia"][0].source_url}
            className={"attachment-large size-large wp-post-image"}
            alt={"Port Elizabeth"}
          />
          <figure className={"anime-1"}>
            <div style={{ width: "70%" }} className={"precinct-text-align"}>
              <h3
                style={{
                  backgroundColor: "unset",
                  padding: "0",
                  borderRadius: "0",
                }}
              >
                {images.content.rendered.replace(/(<([^>]+)>)/gi, "")}
              </h3>
              <h4
                style={{
                  backgroundColor: "unset",
                  padding: "0",
                  borderRadius: "0",
                  marginBottom: "0",
                }}
              >
                {images.acf.Province}
              </h4>
              <p style={{ margin: "20px 0", padding: "0" }}>
                {images.acf.qhamacity}
              </p>
              <h3
                style={{
                  backgroundColor: "unset",
                  padding: "0",
                  borderRadius: "0",
                }}
                dangerouslySetInnerHTML={{ __html: images.acf.Number }}
              />
              <h6>{images.acf.Email}</h6>
              <h5 className={"anime-1 no-margin"}>{images.acf.Address}</h5>
              {images.id === 125 ? (
                <Button
                  style={{ margin: "0" }}
                  buttonStyle={"btn--gradient p-2"}
                  type={"button"}
                  onClick={(e) => {
                    OpenModal();
                  }}
                >
                  GET DIRECTIONS
                </Button>
              ) : (
                <Button
                  style={{ margin: "0" }}
                  buttonStyle={"btn--gradient p-2"}
                  type={"button"}
                >
                  GET DIRECTIONS
                </Button>
              )}
            </div>
          </figure>
        </div>
      );
    }
  });
  const formatPrice = (price) => {
    const newPriceFormat = new Intl.NumberFormat().format(price);
    return newPriceFormat;
  };

  const Modal = (
    <Downloads CloseModal={CloseModal} isModalon={isModalon}>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d413.66230973951025!2d25.621703885882763!3d-33.95916700691575!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xad5ac742e7f6e0d5!2sBright%20Destiny%20Hair%20Salon!5e0!3m2!1sen!2sza!4v1600656108424!5m2!1sen!2sza"
        // width={"650"}
        // height={"450"}
        frameborder={"0"}
        style={{ border: "0" }}
        allowfullscreen={""}
        aria-hidden={"false"}
        tabindex={"0"}
        title={"map"}
        className="w-full lg:w-[650px] h-full lg:h-[450px]"
      />
    </Downloads>
  );

  if (isErr === true) {
    return <BadGateway />;
  } else {
    return (
      <>
        {Modal}
        <div className={AnimeCSS()} style={{ overflow: "hidden" }}>
          <section className={"banner"}>
            <div className={"gd"}></div>
            <img
              src={`${APIURL}/wp-content/uploads/2022/06/12.jpg`}
              className={"w-full h-full object-cover"}
              alt={""}
            />
            {ctaHeader.map((cat, i) => {
              return (
                <BannerInfoAlt
                  isFormModalon={isFormModalon}
                  formModalHandleClick={formModalHandleClick}
                  formModalRemoveHandleClick={formModalRemoveHandleClick}
                  key={i}
                >
                  <div
                    dangerouslySetInnerHTML={{ __html: cat.content.rendered }}
                  />
                  <h4>FROM</h4>
                  <p>
                    R{formatPrice(cat.acf.unit_prices)}
                    <em>P/M</em>
                  </p>
                  <Button
                    onClick={formModalHandleClick}
                    buttonStyle="btn--gradient p-5 w-full"
                    type="button"
                  >
                    Sign Up
                  </Button>
                </BannerInfoAlt>
              );
            })}

            <div className="hidden lg:block absolute z-10 bottom-[5%] left-[4%] lg:bottom-[15%] lg:left-[4%]">
              <Button
                onClick={() => openVideo()}
                buttonStyle="btn--gradient--play"
                type="button"
              >
                play video
              </Button>
            </div>
          </section>

          <section className={"bg-2"}>
            <div className={"bg-ol"}>
              <div className={"wrapper-content-rooms"}>
                <ContentHeader className="text-white text-2xl">
                  <em>visit</em> <i>us</i>
                </ContentHeader>
                {!isLoaded ? (
                  <div
                    className={"loader"}
                    style={{
                      backgroundImage: "none",
                      height: "10vh",
                    }}
                  >
                    <span />
                    <span />
                    <span />
                  </div>
                ) : (
                  listAmenities.reverse()
                )}
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}

export default ContacttUs;
