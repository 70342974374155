import Axios from "axios";
import { useContext, useEffect, useState } from "react";
import CreateApiUrl from "../Context/ApiUrlCTX";

function useApiPosts(state, post_type) {
  const { APIURL } = useContext(CreateApiUrl);

  const [isPostsApi, setPostsApi] = useState([]);
  const [images, setImages] = useState([]);

  const [isLoadedImages, setIsLoadedImages] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isErr, setIsErr] = useState(false);
  const postType = () => {
    if (post_type === undefined) return "_rooms";
    return post_type;
  };
  const PostsApi = `${APIURL}/wp-json/wp/v2/${postType()}/${state}`;

  useEffect(() => {
    Axios.get(PostsApi)
      .then((res) => {
        setPostsApi(res.data);
        setIsLoaded(true);
      })
      .catch((err) => {
        setIsErr(true);
        setIsLoaded(true);
      });
  }, [PostsApi]);

  useEffect(() => {
    if (isPostsApi.length === 0) return;
    const ImagesApi = `${APIURL}/wp-json/wp/v2/media/${isPostsApi?.featured_media}`; //http://localhost/qhamaDev/wp-json/wp/v2/media/37
    Axios.get(ImagesApi)
      .then(setIsLoadedImages(true))
      .then((res) => {
        setImages(res.data);
        setIsLoadedImages(false);
      })
      .catch((err) => {
        setIsLoadedImages(true);
      });
  }, [isPostsApi, APIURL]);

  return { images, isPostsApi, isLoaded, isLoadedImages, isErr };
}

export default useApiPosts;
