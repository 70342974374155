import React from "react";
import ReactDOM from "react-dom";
import CloseIcon from "@mui/icons-material/Close";

function Downloads(props) {
  return ReactDOM.createPortal(
    props.isModalon ? (
      <div className={"modalBG FadeON animate__animated animate__backInUp"}>
        <div className="flex items-center h-screen cursor-default">
          <div className="relative bg-white my-0 mx-auto p-5 w-full lg:w-auto h-full lg:h-auto">
            <div
              onClick={() => {
                props.CloseModal();
              }}
              className="p-[10px] text-white bg-gray-800 absolute right-0 top-0 cursor-pointer z-10"
            >
              <CloseIcon color="inherit" />
            </div>
            {props.children}
          </div>
        </div>
      </div>
    ) : null,
    document.getElementById("modal")
  );
}

export default Downloads;
