import React, { useEffect, useRef, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { CSSTransition } from "react-transition-group";

// CSS
import "./App.css";
import "./styles/tree.css";
import "./styles/contentPreLoader.css";
import "./styles/Slider.css";
import "./styles/error.css";
import "./styles/form.css";
import "./styles/search.css";
import "./styles/partners.css";
import "./styles/table.css";
import "./styles/nav.css";
import "./styles/banner.css";
import "./styles/backToTop.css";
import "./styles/contact.css";
import "./styles/newsLetter.css";
import "./styles/footer.css";
import "./styles/recent.css";
import "./styles/faq.css";
import "./styles/map.css";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// import NavContent from './AppComponents/Nav/NavContent';
import Footer from "./AppComponents/Nav/Footer";
import AppNav from "./AppComponents/Nav/AppNav";

// Fonts
import "./Font/font.css";

import AnimatedComponents from "./AnimatedComponents";
import BackToTopBTN from "./modules/BackToTopBTN";

function App() {
  const [isNavShowing, setisNavShowing] = useState(false);

  const [iconChange, seticonChange] = useState(false);

  const [isSearchon, setisSearchon] = useState(false);

  function HandleClickSearchon() {
    setisSearchon(true);
  }
  function RemoveHandleClickSearchon() {
    setisSearchon(false);
  }

  // Modal
  const [isModalon, setisModalon] = useState(false);

  function HandleClick() {
    setisNavShowing(true);
    seticonChange(true);
  }
  function RemoveHandleClick() {
    setisNavShowing(false);
    seticonChange(false);
  }
  function OpenModal() {
    setisModalon(true);
  }
  function CloseModal() {
    setisModalon(false);
  }

  //  Form Modal
  const [isFormModalon, setisFormModalon] = useState(false);
  const [isVideoPlayerOn, setisVideoPlayerOn] = useState(false);

  function formModalHandleClick() {
    setisFormModalon(true);
  }

  function formModalRemoveHandleClick() {
    setisFormModalon(false);
  }

  function openVideo() {
    setisVideoPlayerOn((prev) => !prev);
  }

  const bannerRef = useRef();
  const bannerRefWidth = bannerRef?.current?.clientWidth;
  const [deviceWidth, setdeviceWidth] = useState(0);

  useEffect(() => {
    setdeviceWidth(bannerRefWidth);
  }, [bannerRefWidth]);

  return (
    <Router>
      <>
        <AppNav
          HandleClick={HandleClick}
          RemoveHandleClick={RemoveHandleClick}
          isNavShowing={isNavShowing}
          iconChange={iconChange}
          isSearchon={isSearchon}
          HandleClickSearchon={HandleClickSearchon}
          RemoveHandleClickSearchon={RemoveHandleClickSearchon}
          setisNavShowing={setisNavShowing}
          seticonChange={seticonChange}
          setisSearchon={setisSearchon}
          formModalHandleClick={formModalHandleClick}
          formModalRemoveHandleClick={formModalRemoveHandleClick}
          isFormModalon={isFormModalon}
          isVideoPlayerOn={isVideoPlayerOn}
          openVideo={openVideo}
          isModalon={isModalon}
        />
        <CSSTransition
          in={true}
          appear={true}
          timeout={1000}
          classNames={"fade"}
        >
          <div className="w-full" style={{ overflow: "hidden" }}>
            <div
              ref={bannerRefWidth}
              // className={isNavShowing ? "translate-x-[30%]" : "translete-x-0"}
            >
              <AnimatedComponents
                seticonChange={seticonChange}
                setisNavShowing={setisNavShowing}
                OpenModal={OpenModal}
                CloseModal={CloseModal}
                isModalon={isModalon}
                formModalHandleClick={formModalHandleClick}
                formModalRemoveHandleClick={formModalRemoveHandleClick}
                isFormModalon={isFormModalon}
                openVideo={openVideo}
                deviceWidth={deviceWidth}
              />
            </div>
            <BackToTopBTN />
          </div>
        </CSSTransition>
        <Footer />
      </>
    </Router>
  );
}

export default App;
