import React, { useContext, useEffect, useState } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import ChevronLeft from "../../icons/chevron-left.svg";
import ChevronRight from "../../icons/chevron-right.svg";
import Button from "../ui/button/button";
import Axios from "axios";
import CreateApiUrl from "../../Context/ApiUrlCTX";

function RoomsSlider() {
  const { APIURL } = useContext(CreateApiUrl);
  const BannerApi = `${APIURL}/?rest_route=/wp/v2/_rooms&_embed`;

  const [isbannerApi, setBannerApi] = useState([]);

  useEffect(() => {
    Axios.get(BannerApi)
      .then((res) => {
        setBannerApi(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [BannerApi]);

  function NextArrow(props) {
    const { style, onClick } = props;
    return (
      <div
        className={"SliderBTN SliderBTNNext"}
        style={{ ...style, left: "unset", right: "0" }}
        onClick={onClick}
      >
        <img src={ChevronRight} alt="" />
      </div>
    );
  }

  function PrevArrow(props) {
    const { style, onClick } = props;
    return (
      <div
        className={"SliderBTN SliderBTNPrev"}
        style={{ ...style }}
        onClick={onClick}
      >
        <img src={ChevronLeft} alt="" />
      </div>
    );
  }

  const settings = {
    dots: false,
    centerMode: true,
    infinite: true,
    autoplay: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  const formatPrice = (price) => {
    const newPriceFormat = new Intl.NumberFormat().format(price);
    return newPriceFormat;
  };

  const listItems = isbannerApi.map((arTest, i) => {
    if (arTest.featured_media === 0) return null;
    return (
      <div
        key={i}
        className="content-room-infos amenitiess w-full h-[35vh] lg:h-[40vh] m-0 relative border-l-[5px] border-white overflow-hidden drop-shadow-lg box-border"
      >
        <img
          width="950"
          height="610"
          alt="Port Elizabeth"
          src={arTest?._embedded["wp:featuredmedia"][0].source_url}
          className="w-full h-full object-cover"
        />
        <figure className="anime-1 w-full top-0 m-0 py-[25px] px-[30px] absolute left-0 h-full bg-black bg-opacity-70 box-border grid align-center">
          <div style={{ width: "max-content" }} className="precinct-text-align">
            <h3
              style={{
                position: "relative",
                backgroundColor: "unset",
                padding: "0",
                borderRadius: "0",
              }}
              className="p-0 relative flex items-center gap-4 text-left text-[1.4em] lg:text-[2.2em] uppercase m-0 font-sans"
            >
              {arTest.title.rendered}{" "}
              {arTest.acf.apartment_view !== "" ? (
                <i className="p-[10px] text-[8px] lg:text-[20px] text-white rounded-full bg-gradient-to-r from-[#ed1c24] to-[#c30b49]">
                  {arTest.acf.apartment_view}
                </i>
              ) : null}
            </h3>
            <h4
              style={{
                backgroundColor: "unset",
                padding: "0",
                borderRadius: "0",
                marginBottom: "0",
              }}
              className="m-0 mst-[10px] text-[21px] align-super font-normal text-white uppercase font-sans"
            >
              From
            </h4>
            <p className="text-[2.5em] lg:text-[4.5em] m-0 font-bold">
              R{formatPrice(arTest.acf.unit_price)}
              <em className="p-[10px] rounded-full text-[16px] ml-2 align-super bg-gradient-to-r from-[#ed1c24] to-[#c30b49]">
                p/m
              </em>
            </p>
            <Button
              style={{ margin: "5px auto 0 auto" }}
              buttonStyle="btn--gradient px-5 py-2 lg:p-5 w-full"
              type="button"
            >
              <Link
                to={{
                  pathname: `/Rooms/${arTest.slug.toLowerCase()}`,
                  state: { prevPath: arTest.id },
                }}
                className="text-white"
              >
                Learn More
              </Link>
            </Button>
          </div>
        </figure>
      </div>
    );
  });

  return (
    <>
      <React.StrictMode>
        <Slider {...settings}>{listItems}</Slider>
      </React.StrictMode>
    </>
  );
}

export default RoomsSlider;
