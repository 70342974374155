import React from "react";
import Button from "../../modules/ui/button/button";
import RecentPost from "../../AppComponents/RecentPost/RecentPost";
import BannerInfoAlt from "../../modules/BannerInfoAlt";
import Form from "../../modules/Form";
import { useLocation } from "react-router-dom";
import ContentHeader from "../../modules/ContentHeader";
import useApiPosts from "../../Utilities/useApiPosts";
import { AnimatePresence, motion } from "framer-motion";

function PostTemplate({
  AnimeCSS,
  formModalHandleClick,
  formModalRemoveHandleClick,
  isFormModalon,
}) {
  const { state } = useLocation();

  const formatPrice = (price) => {
    const newPriceFormat = new Intl.NumberFormat().format(price);
    return newPriceFormat;
  };

  const pages = useApiPosts(state?.prevPath);

  return (
    <div className={AnimeCSS()} style={{ overflow: "hidden" }}>
      <section className="banner">
        <div className="gd"></div>
        <AnimatePresence initial={{ opacity: 0 }}>
          {pages?.isLoadedImages ? null : (
            <motion.img
              initial={{ opacity: 0, scale: 1.5 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 1.5 }}
              src={pages?.images?.source_url}
              className="w-full h-full object-cover"
              alt=""
            />
          )}
        </AnimatePresence>
        <BannerInfoAlt
          formModalHandleClick={formModalHandleClick}
          formModalRemoveHandleClick={formModalRemoveHandleClick}
          isFormModalon={isFormModalon}
        >
          {!pages?.isLoaded ? (
            <div
              className={"loader"}
              style={{ background: "transparent", height: "100%" }}
            >
              <span />
              <span />
              <span />
            </div>
          ) : (
            <div className={"animate__animated animate__zoomIn"}>
              {pages?.isPostsApi.acf?.apartment_view === "" ? null : (
                <em>{pages?.isPostsApi.acf?.apartment_view}</em>
              )}

              <h1
                dangerouslySetInnerHTML={{
                  __html: pages?.isPostsApi?.acf?.header_text,
                }}
              />

              <h4>FROM</h4>
              <p>
                R{formatPrice(pages?.isPostsApi?.acf?.unit_price)}
                <em>P/M</em>
              </p>
              <Button
                onClick={formModalHandleClick}
                buttonStyle="btn--gradient p-5 w-full"
                type="button"
              >
                Sign Up
              </Button>
            </div>
          )}
        </BannerInfoAlt>
      </section>

      <section className="bg-3">
        <div id="post-192" className="grid grid-cols-1 gap-4 lg:grid-cols-2">
          <div className="live-form-2 p-2 lg:p-5 lg:pt-0">
            <ContentHeader className={"text-[#cb1c1e] p-0 m-0"}>
              PROPERTY <i>ENQUIRY</i>
            </ContentHeader>
            <Form />
          </div>
          {!pages?.isLoaded ? (
            <div
              className="loader"
              style={{ background: "transparent", height: "100%" }}
            >
              <span style={{ background: "#cb1c1e" }} />
              <span style={{ background: "#cb1c1e" }} />
              <span style={{ background: "#cb1c1e" }} />
            </div>
          ) : (
            <section
              className={
                "about-content-text animate__animated animate__fadeInUpBig"
              }
              style={{ width: "100%", margin: "0" }}
              dangerouslySetInnerHTML={{
                __html: pages?.isPostsApi?.content?.rendered,
              }}
            />
          )}
        </div>
      </section>

      <RecentPost />
    </div>
  );
}

export default PostTemplate;
