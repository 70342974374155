import React, { useContext, useEffect, useRef, useState } from "react";
import MainSlider from "../modules/DataSlider/MainSlider";
import SteveBikoPrecinctSlider from "../modules/DataSlider/SteveBikoPrecinctSlider";
import BannerInfo from "../modules/BannerInfo";
import ContentHeader from "../modules/ContentHeader";
import QuickLinks from "../modules/QuickLinks";
import FacilitiesImageCard from "../modules/FacilitiesImageCard";
import Excerpt from "../modules/Excerpt";
import Axios from "axios";
import CreateApiUrl from "../Context/ApiUrlCTX";

function Home({ AnimeCSS, OpenModal, CloseModal, isModalon }) {
  const [ctaHeader, setCtaHeader] = useState([]);

  const { APIURL } = useContext(CreateApiUrl);
  const ctaHeaderApi = `${APIURL}/?rest_route=/wp/v2/cta&_embed`;

  useEffect(() => {
    Axios.get(ctaHeaderApi)
      .then((res) => {
        setCtaHeader(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [ctaHeaderApi]);

  const bannerRef = useRef();
  const bannerRefWidth = bannerRef?.current?.clientWidth;
  const [deviceWidth, setdeviceWidth] = useState(0);

  useEffect(() => {
    setdeviceWidth(bannerRefWidth);
  }, [bannerRefWidth]);

  return (
    <div ref={bannerRef} className={AnimeCSS()} style={{ overflow: "hidden" }}>
      <section className="banner-homes no-img2 h-[320px] lg:h-[675px]">
        <MainSlider bannerRefWidth={deviceWidth} />
        {ctaHeader.map((cta, i) => {
          return (
            <BannerInfo key={i} className="hidden lg:block">
              <div dangerouslySetInnerHTML={{ __html: cta.content.rendered }} />
            </BannerInfo>
          );
        })}
      </section>

      <QuickLinks
        OpenModal={OpenModal}
        CloseModal={CloseModal}
        isModalon={isModalon}
      />

      <section className="overflow-visible relative clear-both h-auto">
        <div className="bg-ol" />
        <ContentHeader className={"text-xl"}>
          Explore <em>Our Facilities</em>
        </ContentHeader>
        <ul className="grid gap-5 lg:gap-0 mb-8 lg:mb-0 lg:flex m-auto p-0 h-auto pt-[1.8%] pb-[1.8%] w-full lg:w-[90%]">
          <FacilitiesImageCard />
        </ul>
      </section>

      <Excerpt />

      <section className="bg-black oveflow-hidden relative">
        <div className="bg-gradient-to-r from-[#ed1c24] to-[#c30b49] overflow-hidden">
          <div className="w-full lg:w-[55vw] m-auto pb-5">
            <ContentHeader className={"text-white text-2xl"}>
              <em>EXPLORE</em> <i>WHAT WE OFFER</i>
            </ContentHeader>
            <SteveBikoPrecinctSlider />
          </div>
        </div>
      </section>
    </div>
  );
}

export default Home;
